import {
  Divider,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  Paper,
  Button,
} from '@mui/material'
import React from 'react'
import _ from 'lodash'
import { headersByCostCenter } from '../../models/headersByCostCenter'
import { useTheme } from '@mui/system'
import { ArrowForward } from '@mui/icons-material'

const SummaryByCostCenterSection = () => {
  const theme = useTheme()
  const list = [
    {
      costCenter: '(6040201) Academy-TSD&ITS',
      typeAllYear: 'ค่าใช้จ่ายทั้งปี (%)',
      typeYTD: 'YTD (%) 01/2023 - 12/2023',
      actualAllYear: '533,237.27 ฿',
      budgetAllYear: '1,134,345.00 ฿',
      actualYTD: '533,237.27 ฿',
      budgetYTD: '1,134,345.00 ฿',
    },
    {
      costCenter: '(6040201) Academy-FNT&PPD&APB',
      typeAllYear: 'ค่าใช้จ่ายทั้งปี (%)',
      typeYTD: 'YTD (%) 01/2023 - 12/2023',
      actualAllYear: '533,237.27 ฿',
      budgetAllYear: '1,134,345.00 ฿',
      actualYTD: '533,237.27 ฿',
      budgetYTD: '1,134,345.00 ฿',
    },
    {
      costCenter: '(6040201) Academy-FNT&PPD&APB',
      typeAllYear: 'ค่าใช้จ่ายทั้งปี (%)',
      typeYTD: 'YTD (%) 01/2023 - 12/2023',
      actualAllYear: '533,237.27 ฿',
      budgetAllYear: '1,134,345.00 ฿',
      actualYTD: '533,237.27 ฿',
      budgetYTD: '1,134,345.00 ฿',
    },
    {
      costCenter: '(6040201) Academy-FNT&PPD&APB',
      typeAllYear: 'ค่าใช้จ่ายทั้งปี (%)',
      typeYTD: 'YTD (%) 01/2023 - 12/2023',
      actualAllYear: '533,237.27 ฿',
      budgetAllYear: '1,134,345.00 ฿',
      actualYTD: '533,237.27 ฿',
      budgetYTD: '1,134,345.00 ฿',
    },
  ]
  return (
    <>
      <Divider />
      <Typography variant="h6">แยกราย Cost Center / Department</Typography>

      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.blue.blueLine}`,
          borderRadius: 2,
          boxShadow: 'none',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {_.map(headersByCostCenter, (header) => {
                return <TableCell>{header.label}</TableCell>
              })}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableRow-root:last-of-type .MuiTableCell-root': {
                borderBottom: 'none',
              },
            }}
          >
            {_.map(list, (li) => {
              return (
                <>
                  <TableRow>
                    {_.map(headersByCostCenter, (header) => {
                      return (
                        <TableCell
                          rowSpan={
                            header.id === 'costCenter' ||
                            header.id === 'viewDetails'
                              ? 2
                              : 1
                          }
                          sx={{
                            backgroundColor:
                              header.id === 'costCenter' ||
                              header.id === 'viewDetails'
                                ? theme.palette.common.white
                                : theme.palette.background.blue,
                          }}
                        >
                          {header.id === 'costCenter' ? (
                            li[header.id]
                          ) : header.id === 'viewDetails' ? (
                            <Button
                              color="primary"
                              variant="text"
                              sx={{ ml: 1 }}
                              endIcon={<ArrowForward />}
                            >
                              แสดงรายละเอียด
                            </Button>
                          ) : (
                            li[`${header.id}AllYear`]
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  <TableRow>
                    {_.map(headersByCostCenter, (header) => {
                      if (
                        header.id === 'costCenter' ||
                        header.id === 'viewDetails'
                      )
                        return null
                      return (
                        <TableCell
                          sx={{
                            background: theme.palette.info.background,
                          }}
                        >
                          {li[`${header.id}YTD`]}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default SummaryByCostCenterSection
