import callAxios from '../../../../utils/baseService'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/inventory/dashboard'
import { getInventoryDashboard } from '../../../../utils/apiPath'
import { mutateFilterPropToBody } from '../components/FilterDrawer/event'

export const fetchOverview = async (station) => {
  const { filter } = store.getState().inventoryDashboard
  const body = {
    ...mutateFilterPropToBody(filter, station),
  }
  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: true,
    }),
  )

  await callAxios
    .post(getInventoryDashboard, body)
    .then(({ data }) => {
      store.dispatch(
        setFieldValue({
          key: 'overview',
          value: data,
        }),
      )
    })
    .catch((err) => {
      console.log(err)
    })

  store.dispatch(
    setFieldValue({
      key: 'isLoading',
      value: false,
    }),
  )
}
