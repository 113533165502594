export const filterItems = [
  {
    label: 'วันที่',
    nameCheckBox: 'startDateChecked',
    nameInput: 'startDate',
    placeholder: 'เลือกวันที่',
    type: 'daterange',
  },
  {
    label: 'คลังทรัพย์สิน',
    nameCheckBox: 'inventoryChecked',
    nameInput: 'inventory',
    placeholder: 'เลือกคลังทรัพย์สิน',
    type: 'select_dropdown',
  },

  {
    label: 'Station',
    nameCheckBox: 'stationChecked',
    nameInput: 'station',
    placeholder: 'เลือก Station',
    type: 'checkbox',
    list: [],
  },
  {
    label: 'หลักสูตร',
    nameCheckBox: 'courseChecked',
    nameInput: 'course',
    placeholder: 'เลือกหลักสูตร',
    type: 'select_dropdown',
  },
]
