import React, { useState } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import HistoryTwoTone from '@mui/icons-material/HistoryTwoTone'
import Edit from '@mui/icons-material/Edit'
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew'
import { COURSE_STATUS } from '../../../../../../../constants/eLearning'
import { setOpenDrawer } from '../../../../../../../redux/slices/eLearning/courseForm'
import { StyledHeaderContainer } from '../../../../../Styled'
import HistoryDrawer from './../History'
import { handleCloseCourseBatch, handleDeleteCourseBatch } from '../../events'
import AddDrawer from '../../AddDrawer'
import { StyledButton, StyledButtonDelete } from './Styled'
import { beforeStartBatchStatus } from './model'

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const { result, isLoading } = useSelector(
    (state) => state.eLearningCourseBatchDetail,
  )
  const status = _.get(result, 'status', '')
  const isDeleted = status === COURSE_STATUS.CANCEL
  const isShowDelete = beforeStartBatchStatus.includes(status)
  const canDelete = _.get(result, 'canDelete', false)
  const canEdit = _.get(result, 'canEdit', false)
  const isCanComplete = _.get(result, 'isCanComplete', false)
  const dispatch = useDispatch()

  return (
    <StyledHeaderContainer id="view" isLoading={isLoading}>
      {isCanComplete && (
        <StyledButton
          data-testid="btn-close"
          variant="contained"
          color="secondary"
          sx={{ color: '#fff' }}
          onClick={() => dispatch(handleCloseCourseBatch(result))}
        >
          <PowerSettingsNew />
          ปิดรอบอบรม
        </StyledButton>
      )}
      <StyledButton data-testid="btn-history" onClick={() => setOpen(true)}>
        <HistoryTwoTone />
        ประวัติการแก้ไข
      </StyledButton>
      {isShowDelete && !isDeleted && canDelete && (
        <StyledButtonDelete
          data-testid="btn-delete"
          onClick={() => dispatch(handleDeleteCourseBatch(result))}
        >
          <DeleteTwoToneIcon />
          ลบ
        </StyledButtonDelete>
      )}
      {!isDeleted && canEdit && (
        <StyledButton
          variant="contained"
          data-testid="btn-edit"
          onClick={async () => {
            dispatch(setOpenDrawer(true))
          }}
        >
          <Edit />
          แก้ไข
        </StyledButton>
      )}
      <AddDrawer />
      <HistoryDrawer isOpen={isOpen} onCloseDrawer={() => setOpen(false)} />
    </StyledHeaderContainer>
  )
}

export default Header
