import { enumStatus } from 'src/components/CRUD/enum/status'
import {
  distributionFilter,
  filterAcquiredSkill,
  filterLevelOfLearner,
  filterProductType,
  filterRoadMap,
  filterTrainingPlatform,
  stationFilter,
  academyZone,
  examinationAssociationFilter,
  examinationRegionList,
  examinationCompanyFilter,
  filterStaffProfile,
  filterJobTitle,
  filterLevel,
  trainingPlanArea,
  filterLicense,
  CRUDListFilterPath,
  departmentAll,
  filterCostCenter,
} from '../../utils/apiPath'

export const bodyLevelOfLearner = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'title',
  title: '',
  status: ['ACTIVE'],
}

export const bodyDistribution = {
  abbreviation: '',
  distribution: '',
  level: [],
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyProductType = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  productType: '',
  status: ['ACTIVE'],
}

export const bodyRoadMap = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  title: '',
  abbreviation: '',
  distribution: [],
  status: ['ACTIVE'],
}

export const bodyTrainingPlatform = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  status: ['ACTIVE'],
  trainingPlatform: '',
  abbreviation: '',
}

export const bodyAcquiredSkill = {
  acquiredSkill: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyStation = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  station: '',
  status: ['ACTIVE'],
}

export const bodyDepartment = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'id',
  department: '',
  status: ['ACTIVE'],
}

export const bodyAssociation = {
  search: '',
  region: [],
  provinceName: '',
  searchStation: '',
  station: '',
  locationCode: '',
  createdBy: '',
  updatedBy: '',
  status: ['ACTIVE'],
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'provinceName',
}

export const bodyRegion = {
  regionName: '',
  createdBy: '',
  updatedBy: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'regionName',
  status: ['ACTIVE'],
}

export const bodyCompany = {
  search: '',
  region: [],
  provinceName: '',
  searchStation: '',
  station: '',
  locationCode: '',
  createdBy: '',
  updatedBy: '',
  status: ['ACTIVE'],
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'academyStationName',
}

const bodyStaffProfile = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  agentCode: '',
  firstNameTH: '',
  lastNameTH: '',
  jobTitle: '',
  department: '',
  station: '',
  reportTo: '',
  position: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyJobTitle = {
  jobTitle: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'sequence',
  level: '',
  status: ['ACTIVE'],
}

export const bodyLevel = {
  level: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

export const bodyLicense = {
  category: '',
  description: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
  licenseId: '',
  createdBy: '',
  updatedBy: '',
}

export const bodyCourseNameEN = {
  courseCode: '',
  englishName: '',
  nameForLearner: '',
  numberOfVersions: -1,
  updatedBy: '',
  limit: -1,
  page: 1,
  order: 'DESC',
  sort: 'updatedAt',
  productType: '',
  status: ['ACTIVE'],
  createdBy: '',
}

export const bodyCategory = {
  limit: -1,
  page: 1,
  order: 'DESC',
  sort: 'updatedAt',
  quickSearch: '',
  name: '',
  updatedBy: '',
  status: ['ACTIVE'],
  createdBy: '',
  openDateFinish: '',
  openDateStart: '',
}

export const bodySubCategory = {
  limit: -1,
  page: 1,
  order: 'DESC',
  sort: 'updatedAt',
  quickSearch: '',
  categoryName: '',
  name: '',
  updatedBy: '',
  status: ['ACTIVE'],
  createdBy: '',
  openDateFinish: '',
  openDateStart: '',
}

export const bodyTag = {
  limit: -1,
  page: 1,
  order: 'DESC',
  sort: 'updatedAt',
  name: '',
  status: ['ACTIVE'],
}

export const bodyCostCenter = {
  costCenter: '',
  limit: -1,
  order: 'ASC',
  page: 1,
  sort: 'id',
  status: ['ACTIVE'],
}

const bodyExpenseCategory = {
  name: '',
  order: 'ASC',
  sort: 'name',
  status: [enumStatus.ACTIVE],
  limit: -1,
  page: 1,
}

const bodyAccountCode = {
  limit: -1,
  page: 1,
  order: 'ASC',
  sort: 'updatedAt',
  quickSearch: '',
  name: '',
  oldAccountCode: '',
  newAccountCode: '',
  category: '',
  updateStartDate: '',
  updateFinishDate: '',
  createStartDate: '',
  createFinishDate: '',
  updatedBy: '',
  status: [enumStatus.ACTIVE],
  createdBy: '',
}

export const listAPIFetchMasterDataOptions = [
  {
    body: bodyRoadMap,
    url: filterRoadMap,
    method: 'post',
    name: 'roadMap',
  },
  {
    body: bodyTrainingPlatform,
    url: filterTrainingPlatform,
    method: 'post',
    name: 'trainingPlatform',
  },
  {
    body: bodyProductType,
    url: filterProductType,
    method: 'post',
    name: 'productType',
  },
  {
    body: bodyDistribution,
    url: distributionFilter,
    method: 'post',
    name: 'distribution',
  },
  {
    body: bodyAcquiredSkill,
    url: filterAcquiredSkill,
    method: 'post',
    name: 'acquiredSkill',
  },
  {
    body: bodyLevelOfLearner,
    url: filterLevelOfLearner,
    method: 'post',
    name: 'levelOfLearner',
  },
  {
    body: bodyStation,
    url: stationFilter,
    method: 'post',
    name: 'station',
  },
  {
    body: bodyStation,
    url: stationFilter,
    method: 'post',
    name: 'stationName',
  },
  {
    body: bodyDepartment,
    url: academyZone,
    method: 'post',
    name: 'department',
  },
  {
    body: bodyAssociation,
    url: examinationAssociationFilter,
    method: 'post',
    name: 'associationUuid',
  },
  {
    body: bodyRegion,
    url: examinationRegionList,
    method: 'post',
    name: 'regionUuid',
  },
  {
    body: bodyCompany,
    url: examinationCompanyFilter,
    method: 'post',
    name: 'companyUuid',
  },
  {
    body: bodyStaffProfile,
    url: filterStaffProfile,
    method: 'post',
    name: 'trainerName',
  },
  {
    body: bodyStaffProfile,
    url: filterStaffProfile,
    method: 'post',
    name: 'trainer',
  },
  {
    body: bodyStaffProfile,
    url: filterStaffProfile,
    method: 'post',
    name: 'trainerStaffID',
  },
  {
    body: bodyStaffProfile,
    url: filterStaffProfile,
    method: 'post',
    name: 'trainerId',
  },
  {
    body: bodyStaffProfile,
    url: filterStaffProfile,
    method: 'post',
    name: 'trainerIdList',
  },
  {
    body: bodyJobTitle,
    url: filterJobTitle,
    method: 'post',
    name: 'jobTitle',
  },
  {
    body: bodyLevel,
    url: filterLevel,
    method: 'post',
    name: 'level',
  },
  {
    url: trainingPlanArea,
    method: 'get',
    name: 'area',
  },
  {
    body: bodyLicense,
    url: filterLicense,
    method: 'post',
    name: 'licenseCategory',
  },
  {
    body: bodyLicense,
    url: filterLicense,
    method: 'post',
    name: 'licenseName',
  },
  {
    body: bodyLicense,
    url: filterLicense,
    method: 'post',
    name: 'licenseDescription',
  },
  {
    body: bodyCategory,
    url: CRUDListFilterPath,
    method: 'post',
    name: 'category',
    headers: {
      'x-type': 'E_CONTENT_CATEGORY',
    },
  },
  {
    body: bodySubCategory,
    url: CRUDListFilterPath,
    method: 'post',
    name: 'subCategory',
    headers: {
      'x-type': 'E_CONTENT_SUB_CATEGORY',
    },
  },
  {
    body: bodyTag,
    url: CRUDListFilterPath,
    method: 'post',
    name: 'tag',
    headers: {
      'x-type': 'E_CONTENT_TAG',
    },
  },
  {
    url: departmentAll,
    method: 'get',
    name: 'departmentAll',
  },
  {
    body: bodyCostCenter,
    url: filterCostCenter,
    method: 'post',
    name: 'costCenter',
  },
  {
    body: bodyExpenseCategory,
    url: CRUDListFilterPath,
    method: 'post',
    name: 'expenseCategory',
    headers: {
      'x-type': 'EXPENSE_CATEGORY',
    },
  },
  {
    body: bodyAccountCode,
    url: CRUDListFilterPath,
    method: 'post',
    name: 'accountCode',
    headers: {
      'x-type': 'ACCOUNT_CODE',
    },
  },
]
