import {
  setIsOpenFilterDrawer,
  setFiltersDashboard,
} from '../../../../redux/slices/budgetManagement'
import { store } from '../../../../App'

export const filterDashboard = async () => {
  const { displayFilters } = store.getState().budgetManagement.dashboard

  store.dispatch(setIsOpenFilterDrawer(false))
  store.dispatch(setFiltersDashboard(displayFilters))
}
