import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledCard } from '../Styled'
import SummarySection from '../SummarySection'
import MonthlySummarySection from '../MonthlySummarySection'
import TimePeriodSelector from '../../components/TimePeriodSelector'
import SummaryByCostCenterSection from '../../components/SummaryByCostCenterSection'

const ContentByAcademy = () => {
  const { tabActive } = useSelector(
    (state) => ({
      tabActive: state.budgetManagement.dashboard.tabActive,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" flexDirection="column" gap={3} my={3}>
      <Typography variant="h6">
        {tabActive === 0 ? 'By Academy' : 'By Cost Center'}
      </Typography>
      <StyledCard>
        <TimePeriodSelector />
        <SummarySection />
        <Divider />
        <MonthlySummarySection />
        {tabActive === 1 && <SummaryByCostCenterSection />}
      </StyledCard>
    </Box>
  )
}

export default ContentByAcademy
