import React from 'react'
import _ from 'lodash'
import { store } from 'src/App'
import {
  loadingDialog,
  openDialog,
  closeDialog,
  stopLoadingDialog,
} from '../../../../../redux/slices/dialog'

import { announceCourseBatchLearner } from '../../../../../services/eLearning/course/form'
import { postIneligibleLearner } from '../../../../../services/eLearning/course/learner'
import ReasonDialog from '../../Form/BatchTab/Learner/ReasonDialog'

export const handleAnnounceLearner =
  (courseBatchUuid, courseBatchLearnerUuid) => (dispatch) => {
    const body = {
      courseBatchUuid: courseBatchUuid,
      courseLearner: [courseBatchLearnerUuid],
    }
    dispatch(
      openDialog({
        title: 'ประกาศผู้มีสิทธิ์เข้าเรียน',
        message:
          'ระบบจะอัปเดตสถานะพร้อมส่งอีเมลยืนยันแจ้งกับผู้เรียนที่มีสิทธิ์ทราบต้องการดำเนินการเลยหรือไม่',
        handleConfirm: async () => {
          dispatch(loadingDialog())
          await dispatch(announceCourseBatchLearner(body))
          dispatch(stopLoadingDialog())
          dispatch(closeDialog())
          window.location.reload()
        },
      }),
    )
  }

export const handleIneligible = (uuid, courseLearner) => {
  store.dispatch(
    openDialog({
      type: 'mini',
      maxWidth: 'form',
      disableButton: true,
      content: (
        <ReasonDialog
          title="เหตุผลที่ไม่มีสิทธิ์เข้าเรียน"
          onSubmit={(remark) =>
            handleSubmitIneligible(uuid, courseLearner, remark)
          }
        />
      ),
    }),
  )
}

export const handleSubmitIneligible = async (uuid, courseLearner, remark) => {
  store.dispatch(loadingDialog())
  const body = {
    courseBatchUuid: uuid,
    courseLearner: [courseLearner],
    remark,
  }
  const response = await store.dispatch(postIneligibleLearner(body))
  store.dispatch(stopLoadingDialog())
  store.dispatch(closeDialog())
  if (_.isEmpty(response.error)) window.location.reload()
}
