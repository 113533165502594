/* eslint-disable no-misleading-character-class */
/* eslint-disable no-useless-escape */
import _ from 'lodash'
import * as yup from 'yup'
import { validateSchemaPrerequisite } from './Prerequisite/events'

export const coursePrerequisiteSchema = yup.array().of(
  yup.array().of(
    yup.array().of(
      yup.object().shape({
        isErrorDefault: yup
          .string()
          .test(
            'test-value',
            'กรุณาเลือกข้อมูลในช่องนี้',
            function (name, value) {
              const itemValue = _.get(value, 'parent', '')
              return validateSchemaPrerequisite(itemValue)
            },
          ),
        isErrorYear: yup
          .string()
          .test(
            'test-value',
            'กรุณาเลือกข้อมูลในช่องนี้',
            function (name, value) {
              const itemValue = _.get(value, 'parent', '')
              const newValue =
                itemValue.year === null ? '' : `${itemValue.year}`
              if (_.isEmpty(itemValue.type)) return true
              if (itemValue.type === 'SERVICEYEAR' && _.isEmpty(newValue)) {
                return false
              }

              return true
            },
          ),
        isErrorMonth: yup
          .string()
          .test(
            'test-value',
            'กรุณาเลือกข้อมูลในช่องนี้',
            function (name, value) {
              const itemValue = _.get(value, 'parent', '')
              const newValue =
                itemValue.month === null ? '' : `${itemValue.month}`
              if (_.isEmpty(itemValue.type)) return true
              if (itemValue.type === 'SERVICEYEAR' && _.isEmpty(newValue))
                return false
              return true
            },
          ),
      }),
    ),
  ),
)

export const validationSchema = yup.object({
  englishName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาอังกฤษเท่านั้น',
      function (value) {
        const regex = /[ก-๏]/g
        const test = regex.test(value)
        return !test
      },
    ),
  thaiName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร')
    .test(
      'test-regex',
      'กรุณากรอกข้อมูลในช่องนี้เป็นภาษาไทยเท่านั้น',
      function (value) {
        if (!value || value === '') return true
        const regex = /[a-zA-Z]/g
        const test = regex.test(value)
        return !test
      },
    ),
  nameForLearner: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร'),
  documentList: yup.array().of(
    yup.object().shape({
      name: yup.string().test('documentList', 'กรุณากรอก', function (name) {
        return !_.isEmpty(name)
      }),
    }),
  ),
  startDate: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(30, 'ระบุได้ไม่เกิน 30 ตัวอักษร'),
  hours: yup
    .number()
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  minutes: yup
    .number()
    .typeError('กรุณากรอกข้อมูลในช่องนี้')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(60)
    .min(0),
  englishCertificateName: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .max(255, 'ระบุได้ไม่เกิน 255 ตัวอักษร'),
  distribution: yup
    .object()
    .test('test-uuid', 'กรุณากรอกข้อมูลในช่องนี้', function (name, value) {
      return value?.parent?.distribution?.uuid || false
    }),
  roadmap: yup
    .object()
    .test('test-uuid', 'กรุณากรอกข้อมูลในช่องนี้', function (name, value) {
      return value?.parent?.roadmap?.uuid || false
    }),
  levelOfLearner: yup.array().min(1, 'กรุณากรอกข้อมูลในช่องนี้'),
  trainingPlatform: yup.array().min(1, 'กรุณากรอกข้อมูลในช่องนี้'),
  acquiredSkill: yup.array().min(1, 'กรุณากรอกข้อมูลในช่องนี้'),
  productType: yup.array().min(1, 'กรุณากรอกข้อมูลในช่องนี้'),
  coursePurpose: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร'),
  courseOutline: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้')
    .max(3000, 'ระบุได้ไม่เกิน 3,000 ตัวอักษร'),
  imageKey: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  courseLevelControl: yup
    .string('กรุณากรอกข้อมูลในช่องนี้ให้ถูกต้อง')
    .required('กรุณากรอกข้อมูลในช่องนี้'),
  isAttendance: yup.boolean(),
  attendanceCount: yup
    .number()
    .test(
      'check-attendanceCount',
      'กรุณากรอก',
      (value, context) => !context.parent.isAttendance || !_.isNil(value),
    )
    .nullable(),
  attendanceCriteria: yup
    .number()
    .test(
      'check-attendanceCriteria',
      'กรุณากรอก',
      (value, context) => !context.parent.isAttendance || !_.isNil(value),
    )
    .nullable(),
  qrCodeTime: yup
    .number()
    .test(
      'check-qrCodeTime',
      'กรุณากรอก',
      (value, context) => !context.parent.isAttendance || !_.isNil(value),
    )
    .nullable(),
  testAndEvaluate: yup.array().of(
    yup.object().shape({
      testAndEvaluateType: yup.string(),
      testOrEvaluateUuid: yup.string().required('กรุณาเลือก'),
      type: yup
        .string()
        .test(
          'check-other',
          'กรุณาเลือก',
          (value, context) =>
            context.parent.testAndEvaluateType != 'E_TESTING' ||
            !_.isNil(value),
        ),
      otherType: yup
        .string()
        .test(
          'check-other',
          'กรุณากรอก',
          (value, context) => context.parent.type != 'Other' || !_.isNil(value),
        )
        .max(100, 'ระบุได้ไม่เกิน 100 ตัวอักษร'),
      testCriteriaType: yup.string().required(),
      criteriaPercent: yup
        .number()
        .test(
          'check-percent',
          'กรุณากรอก',
          (value, context) =>
            context.parent.testCriteriaType != 'REQUIRED_PERCENT' ||
            !_.isNil(value),
        )
        .nullable(),
    }),
  ),
  coursePrerequisite: coursePrerequisiteSchema,
  point: yup.string().when('pointStatus', {
    is: 'ACTIVE',
    then: yup.string().when('pointType', {
      is: 'CUSTOM',
      then: yup
        .string()
        .required('กรุณากรอกข้อมูลในช่องนี้')
        .max(4, 'ระบุได้ไม่เกิน 4 ตัวอักษร'),
    }),
  }),
  learningPoint: yup.string().when('pointStatus', {
    is: 'ACTIVE',
    then: yup.string().when('pointType', {
      is: 'LINK',
      then: yup.string().required('กรุณากรอกข้อมูลในช่องนี้'),
    }),
  }),
})
