export const defaultFilter = {
  startDateChecked: false,
  startDate: '',
  endDate: '',
  displayDate: false,
  inventoryChecked: false,
  inventory: '',
  stationChecked: false,
  station: '',
  courseChecked: false,
  course: '',
}
