import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch } from 'react-redux'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  StyledDrawer,
  StyledFooter,
  StyledGroupBox,
  StyledHeadLabel,
} from '../../../../../components/FilterDrawer/Styled'
import * as events from './event'
import _ from 'lodash'
import DateRangeInput from '../../../../../components/DateRangeInput'
import { useSelector } from 'react-redux'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import { CustomDropdown } from '../../../../../components/CRUD/components/CustomDropdown'
import { filterItems } from '../../model/filterItems'
import { defaultFilter } from '../../model/defaultFilter'
import { setFieldValue } from '../../../../../redux/slices/inventory/dashboard'
import { StyledList } from './styled'

export const ListCheckBox = ({
  listArray,
  stateFilter,
  setFilter,
  // handleChange,
  station,
}) => {
  const list = [
    { label: 'เทส', value: 1 },
    { label: 'เทสสอง', value: 2 },
  ]
  return listArray.map((item, index) => (
    <StyledGroupBox key={index}>
      <FormGroup>
        <FormControlLabel
          label={item.label}
          control={
            <Checkbox
              inputProps={{
                'data-testid': `check-${item.nameCheckBox}`,
              }}
              name={item.nameCheckBox}
              checked={stateFilter[item.nameCheckBox]}
              onChange={(e) => {
                events.handleChange({
                  value: e.target.checked,
                  key: e.target.name,
                  filter: stateFilter,
                  setFilter,
                })
              }}
            />
          }
          sx={!stateFilter[item.nameCheckBox] ? { marginBottom: -1 } : {}}
        />

        {stateFilter[item.nameCheckBox] && (
          <>
            {item.type === 'daterange' && (
              <DateRangeInput
                placeholder={item.placeholder}
                dateState={{
                  startDate: stateFilter.startDate || new Date(),
                  endDate: stateFilter.endDate || new Date(),
                  display: stateFilter.displayDate,
                  key: 'selection',
                }}
                onChange={(selectedDates) => {
                  events.handleSelectDate(selectedDates, stateFilter, setFilter)
                }}
              />
            )}
            {item.type == 'checkbox' && (
              <StyledList>
                <ListResult
                  filter={stateFilter}
                  setFilter={setFilter}
                  arrayResult={
                    item.nameInput == 'station' ? station : item.list
                  }
                  listKeys={item.nameInput}
                />
              </StyledList>
            )}

            {item.type === 'select_dropdown' && (
              <CustomDropdown
                id={item.nameInput}
                name={item.nameInput}
                labelName=""
                fieldName={`${item.nameInput}-filter-dropdown`}
                placeholder={item.placeholder}
                isCustomOption={true}
                customOptions={{ isLoading: false, options: list }}
                handleExtraChange={(event, option) => {
                  setFilter({
                    ...stateFilter,
                    [item.nameInput]: `${option?.value}`,
                  })
                }}
              />
            )}
          </>
        )}
      </FormGroup>
    </StyledGroupBox>
  ))
}

export const ListResult = ({ filter, setFilter, arrayResult, listKeys }) => {
  return arrayResult.map((item, index) => {
    return (
      <FormControlLabel
        key={index}
        label={item.label}
        control={
          <Checkbox
            inputProps={{ 'data-testid': `check-${item.name}` }}
            name={item.name}
            checked={_.get(filter[listKeys], item.name, false)}
            onChange={(e) => {
              events.handleChange({
                value: e.target.checked,
                key: e.target.name,
                listKey: listKeys,
                filter,
                setFilter,
              })
            }}
          />
        }
      />
    )
  })
}

const FilterDrawer = () => {
  const [filter, setFilter] = useState(defaultFilter)
  const [station, setStation] = useState([])
  const dispatch = useDispatch()
  const { open, filterMemo } = useSelector(
    (state) => ({
      filterMemo: state.inventoryDashboard?.filterMemo,
      open: state.inventoryDashboard?.filtersDrawer?.isOpen,
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!_.isEmpty(filterMemo)) {
      setFilter(filterMemo)
      return
    }
    setFilter(defaultFilter)
  }, [open])

  useEffect(() => {
    events.handleFetchStation(setStation)
  }, [])

  return (
    <StyledDrawer open={open} onClose={() => toggleFiltersDrawer(false)}>
      <StyledHeadLabel>
        <Box>
          <Typography variant="h5">ตัวกรอง</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => toggleFiltersDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>

      <ListCheckBox
        listArray={filterItems}
        stateFilter={filter}
        setFilter={setFilter}
        station={station}
        handleChange={() => {}}
      />
      <StyledFooter>
        <Button
          data-testid="btn-clear"
          variant="outlined"
          onClick={async () => {
            // await events.handleChange({
            //   value: false,
            //   key: 'startDateChecked',
            //   filter,
            //   setFilter,
            //   isCheck: true,
            // })
            setFilter(defaultFilter)
          }}
        >
          ล้าง
        </Button>
        <Button
          data-testid="btn-submit"
          onClick={() => {
            dispatch(events.handleFiltering(filter, station))
            dispatch(setFieldValue({ key: 'filterMemo', value: filter }))
          }}
          variant="contained"
        >
          ยืนยัน
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}
export default FilterDrawer
