import React from 'react'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { StyledDrawer, StyledHeadLabel, StyledFooter } from '../Styled'
import { togglDetailDrawer } from '../../handler/handleClickOpenDrawer'
import { shallowEqual, useSelector } from 'react-redux'
import AllClassList from './components/AllClassList'
import TopList from './components/TopList'
import { Typography } from '@mui/material'
import { drawerType } from '../../enums/drawerEnum'
import { convertNumberWithComma } from '../../../../../utils/lib'

const DetailDrawer = () => {
  const { open, title, type, classCount } = useSelector(
    (state) => ({
      open: state.crud.mixModuleDashboard?.detailDrawer?.isOpen,
      title: state.crud.mixModuleDashboard?.detailDrawer?.title,
      type: state.crud.mixModuleDashboard?.detailDrawer?.type,
      classCount: state.crud.mixModuleDashboard?.detailDrawer?.classCount,
    }),
    shallowEqual,
  )

  return (
    <StyledDrawer
      open={open}
      onClose={() => togglDetailDrawer(false)}
      customWidth={'85%'}
    >
      <StyledHeadLabel>
        <Box>
          <Typography variant={'h5'}>{title}</Typography>
          <IconButton
            data-testid="btn-close-drawer"
            color="primary"
            component="span"
            onClick={() => togglDetailDrawer(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </StyledHeadLabel>
      <Box p={2}>
        จำนวนรวม <b>{classCount ? convertNumberWithComma(classCount) : 0}</b>{' '}
        คลาส
      </Box>
      <Box>
        {type === drawerType.ALL_CLASS && <AllClassList />}
        {type === drawerType.TOP_LIST && <TopList />}
      </Box>
      <StyledFooter>
        <Button onClick={() => togglDetailDrawer(false)} variant="contained">
          ปิด
        </Button>
      </StyledFooter>
    </StyledDrawer>
  )
}

export default DetailDrawer
