import { useEffect, useState } from 'react'
import { StyledCard } from '../../Styled'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setSelected,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/table'
// import callAxios from '../../../../../../utils/baseService'
import _ from 'lodash'
import { initialState } from '../../../../../../redux/slices/table/model'
import { store } from '../../../../../../App'
import { downloadFile } from '../../../../../../services/util'
import FixedTable from '../../../components/FixedTable'

const AllClassList = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort } = table
  const { filterProp } = filter
  const { searchText } = search
  useEffect(() => {
    dispatch(
      fetchDataList('initial', table, page, filterProp, searchText, setInitial),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(fetchDataList('fetch', table, page, filterProp, searchText))
    }
  }, [limit, order, page, sort])

  return (
    <>
      <StyledCard id="topList-table">
        <FixedTable />
      </StyledCard>
    </>
  )
}

export const headCells = [
  {
    id: 'no',
    label: 'ลำดับ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'courseNameEN',
    label: 'ชื่อหลักสูตร EN',
    disablePadding: false,
    minWidth: '220px',
  },
  {
    id: 'distribution',
    label: 'ช่องทางการจัดจำหน่าย',
    disablePadding: false,
  },
  {
    id: 'station',
    label: 'Station',
    disablePadding: false,
  },
  {
    id: 'trainingDate',
    label: 'Training Date',
    disablePadding: false,
    minWidth: '200px',
  },
  {
    id: 'openRegisterA',
    label: 'จำนวนคนเปิดรับสมัคร (A) (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'openRegisterB',
    label: 'จำนวนคนเปิดรับสมัคร (B) (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'B_A_percent',
    label: 'B/A(%)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'attendeeC',
    label: 'เข้าอบรม (C) (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'C_B_percent',
    label: 'C/B (%)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'notAttendCount',
    label: 'ไม่เข้าอบรม (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'passCount',
    label: 'ผ่านการอบรม (คน)',
    disablePadding: false,
    isNumber: true,
    headCellBgColor: '#1CC54E80',
  },
  {
    id: 'notPassCount',
    label: 'ไม่ผ่านการอบรม (คน)',
    disablePadding: false,
    isNumber: true,
    headCellBgColor: '#C9143280',
  },
  {
    id: 'expenseSummary',
    label: 'Expense Summary by Class (บาท)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'costHead',
    label: 'Cost/Head (บาท)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'คำสั่ง',
    width: '40px',
    minWidth: '40px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: ``,
  },
]

export const fetchDataList =
  (method, table, page, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const realPage = page <= 0 ? 1 : +page
    const title = isInitial ? '' : _.get(filter, 'name', keyword)
    const sortBy = tableProps.sort
    const body = {
      limit: tableProps.limit,
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy,
      type: _.get(filter, 'type', []),
      name: method == 'filter' ? keyword : title,
    }
    console.log('all class list', body)

    const results = [
      {
        no: '1',
        courseNameEN: 'courseNameEN',
        distribution: 'Agency',
        station: 'G-Tower',
        trainingDate: '17/11/2024 - 17/11/2024',
        openRegisterA: 0,
        openRegisterB: 5,
        B_A_percent: 15,
        attendeeC: 20,
        C_B_percent: 28.5,
        notAttendCount: 25,
        passCount: 30,
        notPassCount: 40,
        expenseSummary: 450,
        costHead: 1000,
      },
      {
        no: '2',
        courseNameEN: 'courseNameEN 2',
        distribution: 'Agency',
        station: 'G-Tower',
        trainingDate: '17/11/2024 - 17/11/2024',
        openRegisterA: 0,
        openRegisterB: 5,
        B_A_percent: 15,
        attendeeC: 20,
        C_B_percent: 28.5,
        notAttendCount: 25,
        passCount: 30,
        notPassCount: 40,
        expenseSummary: 450,
        costHead: 1000,
      },
    ]

    dispatch(
      setInitialTable({
        rows: results,
        allCount: results.length,
        headCells: headCells,
        placeholder: 'ค้นหา',
        searchKey: 'courseNameEN',
        canFilter: false,
        hideFilter: true,
        isCheckBox: false,
        handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
        onDownload: (selected, sort, order) =>
          dispatch(onDownload(selected, sort, order)),
      }),
    )
    dispatch(setSelected(results.map((item) => item.no)))

    // await axios
    // const isCancel = await callAxios
    //   .post(eEvaluationFilter, body)
    //   .then(({ data }) => {
    //     const rows = data.result.map((item) => ({
    //       ...item,
    //       updatedBy: item.updatedBy.name,
    //       eEvaluationType: _.capitalize(item.eEvaluationType),
    //     }))
    //     dispatch(
    //       setInitialTable({
    //         rows,
    //         allCount: data.totalCount,
    //         headCells: modifiedHeadCells,
    //         placeholder: 'ค้นหา',
    //         searchKey: 'courseNameEN',
    //         canFilter: false,
    //         isCheckBox: false,
    //         handleSearch: (text) =>
    //           dispatch(handleQuickSearch(tableProps, text)),
    //       }),
    //     )
    //   })
    //   .catch((e) => {
    //     dispatch(
    //       setInitialTable({
    //         rows: [],
    //         allCount: 0,
    //         headCells: headCells,
    //       }),
    //     )
    //     return e.message?.includes('method')
    //   })

    setInitial && setInitial(false)
    // if (!isCancel) dispatch(stopLoading())
  }

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const { mixModuleDashboard } = store.getState().crud
  const title = `${mixModuleDashboard?.detailDrawer?.title ?? ''}`
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    list: selected,
    type: mixModuleDashboard?.detailDrawer?.type ?? '',
  }
  await dispatch(
    downloadFile({
      url: ``,
      body: body,
      fileName: `รายการ${title}.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, 1, null, text))
}

export default AllClassList
