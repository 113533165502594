export const headersByCostCenter = [
  {
    id: 'costCenter',
    label: 'Cost center',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'actual',
    label: 'Actual',
  },
  {
    id: 'budget',
    label: 'Budget',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'viewDetails',
    label: '',
  },
]
