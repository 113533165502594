import { Box, Card, Drawer, CircularProgress } from '@mui/material'
import styled from '@mui/system/styled'

export const shouldForwardProp = {
  shouldForwardProp: (props) =>
    props !== 'customWidth' &&
    props !== 'isLoading' &&
    props !== 'isEdit' &&
    props !== 'isError' &&
    props !== 'isUploadLoading' &&
    props !== 'isScrolled' &&
    props !== 'isDraft',
}

export const LoadingPage = styled(CircularProgress)(({ isLoading }) => ({
  position: 'fixed',
  display: isLoading ? 'block' : 'none',
  top: '50%',
  left: '50%',
  color: 'primary.main',
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Dot = styled(Box)(({ color }) => ({
  width: 8,
  height: 8,
  background: color,
  borderRadius: '50%',
}))

export const StyledCard = styled(
  (props) => <Card {...props} />,
  shouldForwardProp,
)(({ theme, isLoading }) => ({
  minWidth: 275,
  padding: theme.spacing(2),
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.blue.blueLine}`,
  boxShadow: '0px 12px 40px 0px rgba(73, 118, 186, 0.14)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.blue.blueLine}`,
  borderRadius: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

export const StyledDrawer = styled((props) => (
  <Drawer anchor="right" {...props} />
))(() => ({
  '& .MuiPaper-root': {
    width: 350,
  },
  height: '100vh',
}))

export const StyledHeadLabel = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.palette?.background?.paper,
  marginBottom: theme.spacing(3),
  zIndex: 2,
  '& > .MuiBox-root': {
    margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(2)}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minWidth: 300,
    flexDirection: 'row',
    '& .MuiIconButton-root': { padding: 0 },
  },
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  position: 'sticky',
  bottom: 0,
  zIndex: 2,
  backgroundColor: theme?.palette?.background?.paper,
  '& .MuiButton-root': { width: 144 },
  [theme.breakpoints.down('lg')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(7)}`,
  },
}))
