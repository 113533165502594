import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'
import { StyledGroupBox } from '../../../../../components/FilterDrawer/Styled'
import _ from 'lodash'
import SelectGroupChip from '../../../../../components/SelectGroupChip'
import { handleChange } from '../../handler/handleChange'

export const ListCheckbox = () => {
  const { displayFilters, filtersList } = useSelector(
    (state) => ({
      displayFilters: state.budgetManagement.dashboard.displayFilters,
      filtersList: state.budgetManagement.dashboard.filtersList,
    }),
    shallowEqual,
  )

  return _.map(filtersList, (item, index) => {
    return (
      <StyledGroupBox key={index}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{
                  'data-testid': `check-${item.nameCheckBox}`,
                }}
                name={item.nameCheckBox}
                checked={displayFilters[item.nameCheckBox]}
                onChange={(e) => {
                  handleChange({
                    value: e.target.checked,
                    key: e.target.name,
                    isCheck: true,
                  })
                }}
              />
            }
            label={item.label}
            sx={!displayFilters[item.nameCheckBox] ? { marginBottom: -1 } : {}}
          />

          {displayFilters[item.nameCheckBox] && (
            <>
              {item.type === 'select' && (
                <SelectGroupChip
                  boxSx={{ maxWidth: 269 }}
                  id={item.nameInput}
                  name={item.nameInput}
                  placeholder={item.placeholder}
                  options={_.get(
                    displayFilters,
                    `${item.nameInput}Options`,
                    [],
                  )}
                  value={displayFilters[item.nameInput]}
                  onChange={(list) =>
                    handleChange({ key: item.nameInput, value: list })
                  }
                />
              )}
            </>
          )}
        </FormGroup>
      </StyledGroupBox>
    )
  })
}
