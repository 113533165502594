import { Typography } from '@mui/material'
import React from 'react'
import { Dot, Row } from '../Styled'

const DotWithLabel = ({ label, color }) => (
  <Row gap={1}>
    <Dot color={color} />
    <Typography variant="body1b" display="inline">
      {label}
    </Typography>
  </Row>
)
export default DotWithLabel
