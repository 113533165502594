import { Typography } from '@mui/material'
import React from 'react'
import DotWithLabel from '../DotWithLabel'
import { Column } from '../Styled'

const FinancialColumn = ({ label, amount, color, budgetExceeded = false }) => (
  <Column sx={{ width: '50%' }}>
    <DotWithLabel label={label} color={color} />
    <Typography
      ml={2}
      variant={budgetExceeded ? 'body2b' : 'body2'}
      color={color}
    >
      {amount.toLocaleString()} ฿
    </Typography>
  </Column>
)
export default FinancialColumn
