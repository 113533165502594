import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import _ from 'lodash'
import dayjs from 'dayjs'
import './datePicker.css'
import styled from '@mui/system/styled'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

const DatePicker = (props) => {
  const {
    boxSx,
    labelText,
    required,
    disabled,
    id,
    onChange,
    textError,
    placeholder = 'เลือกวันที่',
    value,
    hideTextError,
    disabledDate,
    disableFuture = false,
    hideDisableDateRange,
    ignoreDateRange,
    disablePast,
    disabledInput,
    disabledStartDate,
    disabledEndDate,
    disabledMonthlyPlan,
    disabledDateRange,
    isShouldDisableDate,
    currentDate,
    variant,
    handleOpen = () => {},
    handleClose = () => {},
    sxTextError = {},
    onAccept,
    minDate,
    maxDate,
    isEnableClear = false,
  } = props

  const [newDate, setNewDate] = useState('')
  const handleChange = (newValue) => {
    if (!_.isEmpty(newValue)) {
      onChange(dayjs(newValue).format(window.__env__.REACT_APP_DATE_DB))
    } else {
      onChange(newValue)
    }
  }

  const handleClear = () => {
    onChange(null)
  }

  const handleAccept = (value) => {
    const newYear = dayjs(new Date(newDate)).year()
    const year = dayjs(new Date(value)).year()
    const sameYear = newYear === year
    handleChange(sameYear ? value : newDate || value)
    setNewDate('')
  }

  const disabledList = []
  if (disabledDate && disabledDate.length > 0) {
    disabledDate.forEach((item) => {
      if (!_.isEqual(ignoreDateRange, item)) {
        var startDate = item.startDate
        var endDate = item.finishDate
        let start = dayjs(startDate)
        const end = dayjs(endDate)
        while (start <= end) {
          disabledList.push(
            dayjs(start).format(window.__env__.REACT_APP_DATE_DB),
          )
          start = dayjs(start).add(1, 'day')
        }
      }
    })
  }
  const disableDateRange = (date) => {
    let isDisabled = false
    const newDate = dayjs(date).format(window.__env__.REACT_APP_DATE_DB)

    if (currentDate && newDate < currentDate) return true

    const concatDate = dayjs(date)
      .hour(0)
      .format(window.__env__.REACT_APP_DATE_DB)
    const find = disabledList.find((item) => concatDate === item)

    if (find) isDisabled = true
    let checkDateStart = dayjs(disabledStartDate).format(
      window.__env__.REACT_APP_DATE_DB,
    )
    let checkDateEnd = dayjs(disabledEndDate).format(
      window.__env__.REACT_APP_DATE_DB,
    )

    if (!disabledDateRange) {
      if (isShouldDisableDate) {
        checkDateStart =
          checkDateStart === 'Invalid Date' ? null : checkDateStart
        checkDateEnd = checkDateEnd === 'Invalid Date' ? null : checkDateEnd

        if (id === 'startDate') {
          if (newDate > checkDateEnd) {
            isDisabled = true
          }
          if (newDate < checkDateEnd) {
            isDisabled = false
          }

          if (checkDateEnd === null) isDisabled = false
        } else if (id === 'finishDate' || id === 'finishDate2') {
          if (newDate < checkDateStart) {
            isDisabled = true
          }
          if (newDate > checkDateStart) {
            isDisabled = false
          }
          if (checkDateStart === null) isDisabled = false
        } else {
          if (
            checkDateStart === null &&
            checkDateEnd !== null &&
            newDate > checkDateEnd
          ) {
            isDisabled = true
          } else if (
            checkDateStart !== null &&
            checkDateEnd === null &&
            newDate < checkDateStart
          ) {
            isDisabled = true
          } else if (
            checkDateStart !== null &&
            checkDateEnd !== null &&
            (newDate < checkDateStart || newDate > checkDateEnd)
          ) {
            isDisabled = true
          } else if (checkDateStart === null || checkDateEnd === null) {
            isDisabled = false
          }
        }
      } else {
        if (
          id === 'startDate' &&
          disabledEndDate !== null &&
          newDate > checkDateEnd
        ) {
          isDisabled = true
        }

        if (
          (id === 'finishDate' || id === 'finishDate2') &&
          !_.isEmpty(disabledEndDate) &&
          disabledEndDate < newDate
        ) {
          isDisabled = true
        }
        if (
          (id === 'finishDate' || id === 'finishDate2') &&
          checkDateStart > newDate
        ) {
          isDisabled = true
        }
      }
    }
    if (disabledMonthlyPlan?.year && disabledMonthlyPlan?.year > 0) {
      const getFirstDayOfMonth = new Date(
        disabledMonthlyPlan.year,
        disabledMonthlyPlan.month,
        1,
      )
      const getLastDayOfMonth = new Date(
        disabledMonthlyPlan.year,
        disabledMonthlyPlan.month + 1,
        0,
      )

      const firstDayOfMonth = dayjs(getFirstDayOfMonth).format(
        window.__env__.REACT_APP_DATE_DB,
      )
      const lastDayOfMonth = dayjs(getLastDayOfMonth).format(
        window.__env__.REACT_APP_DATE_DB,
      )
      if (lastDayOfMonth < newDate) {
        isDisabled = true
      }
      if (firstDayOfMonth > newDate) {
        isDisabled = true
      }
    }

    return isDisabled
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{
        ...boxSx,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant={variant ?? 'body2'}
          color={disabled ? 'text.gray' : 'text.secondary'}
          id={id}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled || disabledInput ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box position="relative" sx={{ display: 'flex', alignItems: 'center' }}>
          <DesktopDatePicker
            format="DD/MM/YYYY"
            value={!_.isEmpty(value) ? dayjs(value) : null}
            shouldDisableDate={
              !hideDisableDateRange ? disableDateRange : undefined
            }
            disablePast={disablePast ? disablePast : false}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
            label={''}
            onChange={onChange ? handleChange : () => {}}
            onYearChange={(value) => {
              setNewDate(value)
              handleChange(value)
            }}
            slotProps={{
              textField: {
                placeholder: placeholder ? placeholder : 'เลือกวันที่',
                'data-test-id': 'date-picker',
                error: !_.isEmpty(textError),
                sx: {
                  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#C91432 !important',
                  },
                  width: '100%',
                },
              },
            }}
            renderInput={(params) => (
              <StyledTextField {...params} textError={textError} />
            )}
            disabled={disabledInput}
            PopperProps={{ placement: 'bottom-end' }}
            onOpen={handleOpen}
            onClose={handleClose}
            onAccept={onAccept || handleAccept}
          />

          {isEnableClear && value && (
            <IconButton
              aria-label="clear"
              onClick={handleClear}
              sx={{
                position: 'absolute',
                right: '16%',
                top: '50%',
                padding: '0px',
                color: 'gray',
                transform: 'translateY(-50%)',
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </LocalizationProvider>
      {!hideTextError && (
        <Box sx={{ mb: 3, ...sxTextError }}>
          {textError && textError !== '' && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default DatePicker

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'textError',
})(({ theme, texterror }) => ({
  width: '100%',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor:
      texterror && texterror !== ''
        ? `${theme?.palette?.error?.main} !important`
        : `${theme?.palette?.text?.silver} !important`,
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor:
      texterror && texterror !== ''
        ? `${theme?.palette?.error?.main} !important`
        : `${theme?.palette?.primary?.main} !important`,
  },
}))
