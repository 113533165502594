import _ from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'

const budgetManagement = createSlice({
  name: 'budgetManagement',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setTabActive: (state, { payload }) => {
      state.dashboard.tabActive = payload
    },
    setReduxDashboard: (state, { payload }) => {
      state.dashboard = payload
    },
    setReduxDashboardByKey: (state, { payload }) => {
      const { key, value } = payload
      state.dashboard[key] = value
    },
    setIsOpenFilterDrawer: (state, { payload }) => {
      state.dashboard.isOpenFilterDrawer = payload
    },

    setReduxReport: (state, { payload }) => {
      const { key, value } = payload
      state.report[key] = value
    },
    //class dashboard
    setIsOpenClassDrawer: (state, { payload }) => {
      state.dashboard.isOpenClassDrawer = payload
    },
    //dashboard
    startLoadingDashboard: (state) => {
      state.dashboard.isLoading = true
    },
    stopLoadingDashboard: (state) => {
      state.dashboard.isLoading = false
    },

    setFiltersDashboard: (state, { payload }) => {
      state.dashboard.filters = payload
    },

    setFiltersListDisplay: (state, { payload }) => {
      state.dashboard.filtersList = payload
    },

    setDisplayFilter: (state, { payload }) => {
      state.dashboard.displayFilters = payload
    },
    setWorkTypeDataByKey: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    setIsOpenStaffDrawer: (state, { payload }) => {
      state.dashboard.isOpenStaffDrawer = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setTabActive,
  setInitialReportMP,
  setReduxReport,
  startLoadingDashboard,
  stopLoadingDashboard,
  setFilterDisplay,
  setIsOpenClassDrawer,
  setReduxWorkloadDashboardByKey,
  setReduxDashboard,
  setFiltersDashboard,
  setReduxDashboardByKey,
  setFiltersListDisplay,
  setIsOpenFilterDrawer,
  setDisplayFilter,
  setWorkTypeDataByKey,
  setIsOpenStaffDrawer,
} = budgetManagement.actions

export default budgetManagement.reducer
