import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getFormatDate } from '../../../../utils/lib'
import { BoxWrapper, FilterStyledBox, StyledClearIcon } from '../Styled'
import { handleClearFilter } from '../handler/handleClearFilter'
import { Row } from '../../../../components/Layout/Styled'
import dayjs from 'dayjs'
import _ from 'lodash'

export const FilterBox = () => {
  const { filter, filtersDrawer } = useSelector(
    (state) => ({
      filter: state.inventoryDashboard.filter,
      filtersDrawer: state.inventoryDashboard.filtersDrawer,
    }),
    shallowEqual,
  )
  const startDateFilter = _.isDate(filter?.startDate)
    ? filter?.startDate
    : dayjs(new Date()).utc(true).startOf('month')
  const endDateFilter = _.isDate(filter?.endDate)
    ? filter?.endDate
    : dayjs(new Date()).utc(true)
  const stationFilter = filter?.station ?? []
  const hasFilter =
    stationFilter?.length > 0 || (startDateFilter && endDateFilter)
  const isFilterDate = filtersDrawer?.isFilterDate ?? ''
  const isFilterStation = filtersDrawer?.isFilterStation ?? false
  const courseFilter = filter?.courseChecked ? filter?.course : undefined
  const inventoryFilter = filter?.inventoryChecked
    ? filter?.inventory
    : undefined

  return (
    <Row>
      {hasFilter ? (
        <BoxWrapper>
          {startDateFilter && endDateFilter && (
            <FilterStyledBox>
              วันที่:{' '}
              <strong>
                {getFormatDate(startDateFilter)} -{' '}
                {getFormatDate(endDateFilter)}
              </strong>
              {isFilterDate && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('filterDate')}
                />
              )}
            </FilterStyledBox>
          )}
          {stationFilter?.length > 0 && (
            <FilterStyledBox>
              Station:{' '}
              <strong>
                {stationFilter.map((item) => item.label).join(', ')}
              </strong>
              {isFilterStation && (
                <StyledClearIcon
                  onClick={() => handleClearFilter('stationFilter')}
                />
              )}
            </FilterStyledBox>
          )}
        </BoxWrapper>
      ) : (
        ''
      )}
      {inventoryFilter && (
        <BoxWrapper>
          <FilterStyledBox>
            คลังทรัพย์สิน: <strong>{inventoryFilter}</strong>
            <StyledClearIcon
              onClick={() => handleClearFilter('inventoryFilter')}
            />
          </FilterStyledBox>
        </BoxWrapper>
      )}

      {courseFilter && (
        <BoxWrapper>
          <FilterStyledBox>
            หลักสูตร: <strong>{courseFilter}</strong>
            <StyledClearIcon
              onClick={() => handleClearFilter('courseFilter')}
            />
          </FilterStyledBox>
        </BoxWrapper>
      )}
    </Row>
  )
}
