import _ from 'lodash'
import { store } from '../../App'
import { videoFormat } from './Module/Form/TypeContent/EmbedContent/events'

export const getConfigState = (key) => {
  const configuration = store.getState().eConfiguration
  return _.get(configuration.body, key, null)
}

export const validateOneLink = (value) => {
  if (_.isEmpty(value)) return true

  const splittedUrl = value.slice(8).split('/')
  const validList = splittedUrl.filter((text) =>
    videoFormat.some((format) => text === format),
  )
  return validList.length === 1
}
