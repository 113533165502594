import _ from 'lodash'
import { setDisplayFilter } from '../../../../redux/slices/budgetManagement'
import { store } from '../../../../App'
import { initialState } from 'src/redux/slices/budgetManagement/model'

export const handleChange = ({ key, value, listKey }) => {
  const { displayFilters } = store.getState().budgetManagement.dashboard

  let objState = {
    ...displayFilters,
    [key]: value,
  }

  if (!_.isNil(listKey)) {
    objState = {
      ...objState,
      [listKey]: {
        ...objState[listKey],
        [key]: value,
      },
    }
  }

  const keyWithOutChecked = key.replace('Checked', '')

  if (!value) {
    if (
      _.includes(key, 'Checked') &&
      key !== 'dateChecked' &&
      key !== 'stationChecked'
    ) {
      objState = {
        ...objState,
        [keyWithOutChecked]: _.get(
          initialState,
          `dashboard.filters.${keyWithOutChecked}`,
          [],
        ),
      }
    }
  }

  store.dispatch(setDisplayFilter(objState))
}
