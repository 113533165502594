import { useEffect, useState } from 'react'
import { StyledCard } from '../../Styled'
import { useSelector, useDispatch } from 'react-redux'
import {
  resetTablePage,
  setFilterProp,
  setInitialTable,
  setSearchText,
  setSelected,
  startLoading,
  stopLoading,
} from '../../../../../../redux/slices/table'
// import callAxios from '../../../../../../utils/baseService'
import Table from '../../../../../../components/redux/Table'
import _ from 'lodash'
import { initialState } from '../../../../../../redux/slices/table/model'
import { store } from '../../../../../../App'
import { downloadFile } from '../../../../../../services/util'

const TopList = () => {
  const dispatch = useDispatch()
  const [isInitial, setInitial] = useState(true)
  const { table, search, filter } = useSelector((state) => state.table)
  const { limit, order, page, sort } = table
  const { filterProp } = filter
  const { searchText } = search
  useEffect(() => {
    dispatch(
      fetchDataList('initial', table, page, filterProp, searchText, setInitial),
    )
  }, [])

  useEffect(() => {
    if (!isInitial) {
      dispatch(fetchDataList('fetch', table, page, filterProp, searchText))
    }
  }, [limit, order, page, sort])

  return (
    <>
      <StyledCard id="topList-table">
        <Table />
      </StyledCard>
    </>
  )
}

export const headCells = [
  {
    id: 'no',
    label: 'ลำดับ',
    width: '20px',
    hideSortIcon: true,
  },
  {
    id: 'courseNameEN',
    label: 'ชื่อหลักสูตร EN',
    disablePadding: false,
    minWidth: '220px',
  },
  {
    id: 'distribution',
    label: 'ช่องทางการจัดจำหน่าย',
    disablePadding: false,
  },
  {
    id: 'classCount',
    label: 'จำนวนคลาส',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'attendeeCount',
    label: 'จำนวนผู้เข้าอบรม (คน)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'expenseSummary',
    label: 'Expense Summary by Class (บาท)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'costHead',
    label: 'Cost/Head (บาท)',
    disablePadding: false,
    isNumber: true,
  },
  {
    id: 'action',
    disablePadding: false,
    label: ' ',
    width: '40px',
    minWidth: '40px',
    hideEdit: true,
    hideDelete: true,
    hideSortIcon: true,
    viewPath: ``,
  },
]

export const fetchDataList =
  (method, table, page, filterProp, keyword, setInitial) =>
  async (dispatch) => {
    dispatch(startLoading())
    const isInitial = method == 'initial'
    const tableProps = isInitial ? initialState.table : table
    const filter = isInitial ? null : filterProp
    const realPage = page <= 0 ? 1 : +page
    const title = isInitial ? '' : _.get(filter, 'name', keyword)
    const sortBy = tableProps.sort
    const body = {
      limit: tableProps.limit,
      page: realPage,
      order: tableProps.order.toString().toUpperCase(),
      sort: sortBy,
      type: _.get(filter, 'type', []),
      name: method == 'filter' ? keyword : title,
    }
    console.log('top list', body)

    const results = [
      {
        no: '1',
        courseNameEN: 'courseNameEN',
        distribution: 'distribution',
        classCount: 0,
        attendeeCount: 10,
        expenseSummary: 100,
        costHead: 1000,
      },
    ]

    dispatch(
      setInitialTable({
        rows: results,
        allCount: results.length,
        headCells: headCells,
        placeholder: 'ค้นหา',
        searchKey: 'courseNameEN',
        canFilter: false,
        hideFilter: true,
        isCheckBox: false,
        handleSearch: (text) => dispatch(handleQuickSearch(tableProps, text)),
        onDownload: (selected, sort, order) =>
          dispatch(onDownload(selected, sort, order)),
      }),
    )
    dispatch(setSelected(results.map((item) => item.no)))

    // await axios
    // const isCancel = await callAxios
    //   .post(eEvaluationFilter, body)
    //   .then(({ data }) => {
    //     const rows = data.result.map((item) => ({
    //       ...item,
    //       updatedBy: item.updatedBy.name,
    //       eEvaluationType: _.capitalize(item.eEvaluationType),
    //     }))
    //     dispatch(
    //       setInitialTable({
    //         rows,
    //         allCount: data.totalCount,
    //         headCells: modifiedHeadCells,
    //         placeholder: 'ค้นหา',
    //         searchKey: 'courseNameEN',
    //         canFilter: false,
    //         isCheckBox: false,
    //         handleSearch: (text) =>
    //           dispatch(handleQuickSearch(tableProps, text)),
    //       }),
    //     )
    //   })
    //   .catch((e) => {
    //     dispatch(
    //       setInitialTable({
    //         rows: [],
    //         allCount: 0,
    //         headCells: headCells,
    //       }),
    //     )
    //     return e.message?.includes('method')
    //   })

    setInitial && setInitial(false)
    // if (!isCancel) dispatch(stopLoading())
  }

export const onDownload = (selected, sort, order) => async (dispatch) => {
  dispatch(startLoading())
  const { mixModuleDashboard } = store.getState().crud
  const title = `${mixModuleDashboard?.detailDrawer?.title ?? ''}`
  const body = {
    order: order.toUpperCase(),
    sort: sort,
    list: selected,
    type: mixModuleDashboard?.detailDrawer?.type ?? '',
  }
  await dispatch(
    downloadFile({
      url: ``,
      body: body,
      fileName: `รายการ${title}.xlsx`,
    }),
  )
  dispatch(stopLoading())
}

export const handleQuickSearch = (table, text) => (dispatch) => {
  dispatch(setSearchText(text))
  dispatch(setFilterProp(null))
  dispatch(resetTablePage())
  dispatch(fetchDataList('search', table, 1, null, text))
}

export default TopList
