import { Typography } from '@mui/material'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Row, Column } from '../Styled'
import _ from 'lodash'
import MonthlyFinancialDetailsBox from './MonthlyFinancialDetailsBox'

const MonthlySummarySection = () => {
  const { monthlySummary } = useSelector(
    (state) => ({
      monthlySummary: state.budgetManagement.dashboard.monthlySummary,
    }),
    shallowEqual,
  )
  return (
    <Column gap={1.5}>
      <Typography variant="h6">By Month</Typography>
      <Row flexWrap="wrap" gap={2}>
        {_.map(monthlySummary, (sum, idx) => (
          <MonthlyFinancialDetailsBox idx={idx} key={idx} {...sum} />
        ))}
      </Row>
    </Column>
  )
}

export default MonthlySummarySection
