import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import useTheme from '@mui/system/useTheme'
import styled from '@mui/system/styled'
import _ from 'lodash'

export default function Index(props) {
  const {
    sx,
    boxSx,
    labelText,
    required,
    id,
    name,
    disabled,
    textError,
    subComponent,
    showTextError = true,
    errorSx = { mb: 3 },
    options,
    handleChange,
    handleInputChange,
    defaultValue,
    renderInput,
    renderGroup,
    optionImage,
    isTrainingPlans,
    isHideSort,
    isLoading,
    disableClearable = false,
    isOptionEqualToValue,
    noOptionsText,
    getOptionDisabled,
    groupBy,
    loading,
    filterOptions,
    multiple = false,
    freeSolo = false,
    getOptionLabel = (option) => option.label || '',
    renderTags,
    filterSelectedOptions,
    disableCloseOnSelect,
    onFocus,
    renderOption,
  } = props
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        ...boxSx,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography
          variant="body2"
          color={disabled ? 'text.gray' : 'text.secondary'}
        >
          {labelText}
        </Typography>
        {required && (
          <Typography
            sx={{ lineHeight: 1, ml: 0.5 }}
            color={disabled ? 'text.gray' : 'error'}
          >
            *
          </Typography>
        )}
      </Box>
      <Autocomplete
        loading={loading}
        isOptionEqualToValue={isOptionEqualToValue}
        data-testid={id}
        id={id}
        name={name}
        sx={{
          ...sx,
          '& .MuiOutlinedInput-notchedOutline, &:not(.Mui-focused):hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor:
                textError &&
                textError != '' &&
                `${theme?.palette?.error?.main} !important`,
            },
          input: { maxWidth: 'calc(100% - 72px)' },
        }}
        options={
          isHideSort
            ? options
            : options.sort((a, b) => -b.label.localeCompare(a.label))
        }
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        getOptionDisabled={
          getOptionDisabled ? getOptionDisabled : (option) => option?.disabled
        }
        renderOption={
          renderOption
            ? renderOption
            : (propsOption, option) => {
                if (isLoading) {
                  return (
                    <Typography variant="body1" ml={1}>
                      Loading...
                    </Typography>
                  )
                }

                const optionIndex = _.get(propsOption, 'data-option-index', 0)
                const key = `${optionIndex}-${option.value}`
                return (
                  <Box {...propsOption} key={key} id={option.value}>
                    {optionImage && (
                      <ListStaffImage
                        src={`${window.__env__.REACT_APP_API_URL}/file/${option.image}`}
                      />
                    )}

                    <Typography
                      variant="body1"
                      id={option.value}
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {isTrainingPlans
                        ? `${option.firstNameTH} ${option.lastNameTH}`
                        : option.label}
                    </Typography>
                  </Box>
                )
              }
        }
        renderInput={renderInput}
        renderGroup={renderGroup}
        renderTags={renderTags}
        groupBy={groupBy}
        onChange={(e, element) => handleChange(e, element)}
        onInputChange={(e) => {
          if (typeof handleInputChange === 'function') handleInputChange(e)
        }}
        value={defaultValue}
        disableClearable={disableClearable}
        noOptionsText={noOptionsText}
        filterOptions={filterOptions}
        freeSolo={freeSolo}
        multiple={multiple}
        filterSelectedOptions={filterSelectedOptions}
        disableCloseOnSelect={disableCloseOnSelect}
        onFocus={onFocus}
      />
      {subComponent}
      {showTextError && (
        <Box sx={errorSx}>
          {textError && textError != '' && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}

const ListStaffImage = styled('img')({
  width: '35px',
  height: '35px',
  objectFit: 'cover',
  borderRadius: '50%',
  marginRight: '10px',
})
