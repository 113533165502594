import _ from 'lodash'
import axios from 'axios'
import { uploadFileKey } from '../../utils/apiPath'

export const handleInsertImage = (quillRef) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.click()
  input.onchange = async () => {
    const files = input.files
    const file = files[0]
    if (/^image\//.test(file.type)) {
      const body = new FormData()
      body.append('file', file)
      try {
        const response = await axios.post(`${uploadFileKey}/editor`, body)
        const data = _.get(response, 'data', undefined)
        console.log('data', data)
        const url = `${window.__env__.REACT_APP_API_URL}/file/get${data.key}`
        const editor = quillRef.current.getEditor()
        editor.insertEmbed(editor.getSelection(), 'image', url)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
