import { Typography } from '@mui/material'
import React from 'react'
import DotWithLabel from '../DotWithLabel'
import { Row } from '../Styled'

const FinancialRow = ({ label, amount, color, budgetExceeded = false }) => (
  <Row justifyContent="space-between">
    <DotWithLabel label={label} color={color} />
    <Typography variant={budgetExceeded ? 'body2b' : 'body2'} color={color}>
      {amount.toLocaleString()} ฿
    </Typography>
  </Row>
)
export default FinancialRow
