import { store } from '../../../../App'
import { fetchOverview } from './fetchOverview'
import { setFieldValue } from '../../../../redux/slices/inventory/dashboard'
import _ from 'lodash'

export const handleClearFilter = (key) => {
  const { filtersDrawer, filter } = store.getState().inventoryDashboard
  const countFilterTotal = filtersDrawer.filterTotal
  let newFilter = { ...filter }
  if (key === 'filterDate') {
    newFilter = {
      ...filter,
      startDateChecked: false,
      startDate: '',
      endDate: '',
      displayDate: false,
    }
    store.dispatch(
      setFieldValue({
        key: 'filter',
        value: newFilter,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filtersDrawer.isFilterDate',
        value: false,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filtersDrawer.isClearFilter',
        value: 'date',
      }),
    )
  }
  if (key === 'inventoryFilter') {
    newFilter = {
      ...filter,
      inventoryChecked: false,
      inventory: '',
    }
    store.dispatch(
      setFieldValue({
        key: 'filter',
        value: newFilter,
      }),
    )
  }
  if (key === 'stationFilter') {
    const user = JSON.parse(localStorage.getItem('user'))
    const userStation = _.get(user, 'area[0].station')
    newFilter = {
      ...filter,
      stationChecked: true,
      station: [
        {
          label: userStation.station,
          name: `${userStation.station.toLowerCase()}Checked`,
          uuid: userStation.uuid,
        },
      ],
    }
    store.dispatch(
      setFieldValue({
        key: 'filter',
        value: newFilter,
      }),
    )
    store.dispatch(
      setFieldValue({
        key: 'filtersDrawer.isFilterStation',
        value: false,
      }),
    )
  }
  if (key === 'courseFilter') {
    newFilter = {
      ...filter,
      courseChecked: false,
      course: '',
    }
    store.dispatch(
      setFieldValue({
        key: 'filter',
        value: newFilter,
      }),
    )
  }
  store.dispatch(
    setFieldValue({
      key: 'filterMemo',
      value: newFilter,
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'filtersDrawer.filterTotal',
      value: countFilterTotal - 1,
    }),
  )
  fetchOverview()
}
