import React from 'react'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import DeleteTwoTone from '@mui/icons-material/DeleteTwoTone'
import Add from '@mui/icons-material/Add'

import Select from '../../../components/Input/Select'
import ContentSelect from './ContentSelect'

import { listOptionMonth, listOptionYear, optionType } from './model'

import * as Styled from './Styled'
import * as events from './events'

const Index = ({ formik, license, allCourse, disabled }) => {
  const coursePrerequisite = [..._.get(formik, 'values.coursePrerequisite', [])]
  const errorsPrerequisite = [..._.get(formik, 'errors.coursePrerequisite', [])]

  const listOptionSelect = events.mapListOptionSelect({
    license,
    allCourse,
  })

  return (
    <>
      {coursePrerequisite.length > 0 &&
        coursePrerequisite.map((itemList, listIndex) => {
          let checkDisabled = false
          const propsEvents = {
            listIndex,
            coursePrerequisite,
            formik,
          }
          return (
            <Box key={listIndex}>
              <Styled.BoxContent>
                <Box sx={{ m: 2 }}>
                  {/* header */}
                  <Styled.BoxHederGroup>
                    <Typography
                      variant="body1b"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {listIndex > 0 && (
                        <Typography variant="h5" sx={{ mr: 1 }}>
                          หรือ
                        </Typography>
                      )}
                      เงื่อนไข กลุ่ม {listIndex + 1}
                    </Typography>
                    <IconButton
                      onClick={() => events.handleDeleteByCard(propsEvents)}
                      disabled={disabled}
                    >
                      <DeleteTwoTone />
                    </IconButton>
                  </Styled.BoxHederGroup>
                  {/* content */}
                  {itemList.map((itemContent, contentIndex) => {
                    const disabledItem = events.checkDisabled(itemContent)
                    if (disabledItem) checkDisabled = disabledItem
                    return (
                      <Box key={`${listIndex}${contentIndex}`}>
                        {contentIndex > 0 && (
                          <Styled.TextLabel variant="h5">และ</Styled.TextLabel>
                        )}
                        {/* card group */}
                        <Styled.CardGroup>
                          {itemContent.map((item, itemIndex) => {
                            let listOptions = []
                            if (item.type !== 'SERVICEYEAR') {
                              listOptions = events.mapOptionSelect(
                                item.type,
                                listOptionSelect,
                              )
                            }
                            const propsEventsContent = {
                              ...propsEvents,
                              contentIndex,
                              itemIndex,
                            }
                            const key = `${listIndex}${contentIndex}${itemIndex}`

                            return (
                              <Box key={key}>
                                <Styled.BoxCard>
                                  <Select
                                    dataTestid={`select-type-${itemIndex}`}
                                    boxSx={{
                                      mt: 2,
                                      minWidth: 130,
                                      maxWidth: 130,
                                    }}
                                    id="type"
                                    name="type"
                                    labelText="ประเภท"
                                    type="basic"
                                    options={optionType}
                                    handleChange={(e) =>
                                      events.handleChangeSelect({
                                        ...propsEventsContent,
                                        keyValue: 'type',
                                        value: _.get(e, 'target.value', ''),
                                      })
                                    }
                                    placeholder="กรุณาเลือก"
                                    value={item.type}
                                    disabled={disabled}
                                  />

                                  {item.type !== 'SERVICEYEAR' ? (
                                    <ContentSelect
                                      type={item.type}
                                      itemIndex={itemIndex}
                                      listOptions={listOptions}
                                      label="ชื่อ"
                                      value={events.findItem({
                                        list: listOptions,
                                        item,
                                      })}
                                      onChangeSelect={(e) =>
                                        events.handleChangeSelect({
                                          ...propsEventsContent,
                                          keyValue: events.mapKeyValue(
                                            item.type,
                                          ),
                                          value: _.get(e, 'target.id', null),
                                        })
                                      }
                                      listIndex
                                      textError={_.get(
                                        errorsPrerequisite,
                                        `[${listIndex}][${contentIndex}][${itemIndex}].isErrorDefault`,
                                        '',
                                      )}
                                      disabled={disabled}
                                    />
                                  ) : (
                                    <Styled.BoxContentService>
                                      <ContentSelect
                                        type={`year-${item.type}`}
                                        itemIndex={itemIndex}
                                        listOptions={listOptionYear}
                                        label="ปี"
                                        value={events.findItem({
                                          list: listOptionYear,
                                          isYear: true,
                                          item,
                                        })}
                                        onChangeSelect={(e) =>
                                          events.handleChangeSelect({
                                            ...propsEventsContent,
                                            keyValue: 'year',
                                            value: _.get(e, 'target.id', null),
                                          })
                                        }
                                        textError={_.get(
                                          errorsPrerequisite,
                                          `[${listIndex}][${contentIndex}][${itemIndex}].isErrorYear`,
                                          '',
                                        )}
                                        disabled={disabled}
                                      />

                                      <Select
                                        boxSx={{ mt: 2 }}
                                        dataTestid={`select-month-${item.type}-${itemIndex}`}
                                        id="month"
                                        name="month"
                                        labelText="เดือน"
                                        type="basic"
                                        options={listOptionMonth}
                                        handleChange={(e) =>
                                          events.handleChangeSelect({
                                            ...propsEventsContent,
                                            keyValue: 'month',
                                            value: _.get(e, 'target.value', ''),
                                          })
                                        }
                                        placeholder="กรุณาเลือก"
                                        value={item.month}
                                        textError={_.get(
                                          errorsPrerequisite,
                                          `[${listIndex}][${contentIndex}][${itemIndex}].isErrorMonth`,
                                          '',
                                        )}
                                        disabled={disabled}
                                      />
                                    </Styled.BoxContentService>
                                  )}

                                  {/* button , icon */}
                                  <Styled.BoxButton sx={{ mt: '42px' }}>
                                    <Button
                                      disabled={disabledItem || disabled}
                                      data-testid={`btn-or-${itemIndex}`}
                                      id={`btn-or-${itemIndex}`}
                                      name={`btn-or-${itemIndex}`}
                                      variant="outlined"
                                      size="m"
                                      onClick={() =>
                                        events.handleAddSubGroup({
                                          ...propsEvents,
                                          contentIndex,
                                          itemIndex,
                                          item,
                                        })
                                      }
                                    >
                                      <Typography variant="buttonl">
                                        หรือ
                                      </Typography>
                                    </Button>
                                    <IconButton
                                      data-testid={`icon-delete-${itemIndex}`}
                                      disabled={
                                        (contentIndex == 0 &&
                                          itemContent.length == 1) ||
                                        disabled
                                      }
                                      onClick={() =>
                                        events.handleDeleteSubGroup({
                                          ...propsEvents,
                                          contentIndex,
                                          itemIndex,
                                          itemContent,
                                        })
                                      }
                                    >
                                      <DeleteTwoTone
                                        color={
                                          itemContent.length == 1
                                            ? 'action.disabled'
                                            : 'action.active'
                                        }
                                      />
                                    </IconButton>
                                  </Styled.BoxButton>
                                </Styled.BoxCard>
                              </Box>
                            )
                          })}
                        </Styled.CardGroup>
                      </Box>
                    )
                  })}
                  {/* footer */}
                  <Styled.BoxButtonAdd>
                    <Button
                      disabled={checkDisabled || disabled}
                      data-testid={`btn-and-${listIndex}`}
                      id={`btn-and-${listIndex}`}
                      name={`btn-and-${listIndex}`}
                      variant="outlined"
                      startIcon={
                        <Add
                          color={
                            checkDisabled || disabled
                              ? 'action.disabled'
                              : 'primary'
                          }
                        />
                      }
                      size="m"
                      onClick={() =>
                        events.handleAddConditionInGroup(propsEvents)
                      }
                    >
                      <Typography variant="buttonl">และ เงื่อนไข</Typography>
                    </Button>
                  </Styled.BoxButtonAdd>
                </Box>
              </Styled.BoxContent>
            </Box>
          )
        })}
      <Box>
        <Button
          data-testid="btn-and-condition"
          id="btn-and-condition"
          name="btn-and-condition"
          variant="contained"
          startIcon={<Add />}
          size="m"
          onClick={() =>
            events.handleAddCondition({ formik, coursePrerequisite })
          }
          disabled={disabled}
        >
          <Typography variant="buttonl">หรือ เงื่อนไขกลุ่มใหม่</Typography>
        </Button>
      </Box>
    </>
  )
}
export default Index
