import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
// import _ from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
// import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'

// import useTheme from '@mui/system/useTheme'
// import Dot from '../../../components/redux/Dot'
import Breadcrumbs from '../../../../../../components/Breadcrumbs'
import Table from '../../../../../../components/redux/Table'
// import { learnerStatus } from '../../../constants/manageClass'
import Header from './Header'
// import { getBatchDetail } from '../../../../../../services/eLearning/course/form'
import { getEConfiguration } from '../../../../../../services/eConfiguration'

import {
  // checkColorStatus,
  fetchDataList,
} from './event'

import {
  StyledCard,
  StyledContainer,
  StyledContent,
  LoadingPage,
} from './Styled'
import { breadcrumbLearner } from './model'
import FilterDrawer from './FilterDrawer'
import _ from 'lodash'
import { fetchELearningCourseBatch } from '../View/events'

const Learner = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  const { result } = useSelector((state) => state.eLearningCourseBatchDetail)
  const { table, search, filter } = useSelector(
    (state) => state.table,
    shallowEqual,
  )
  const { limit, order, page, sort, isLoading } = table
  const { filterProp, isFilterDrawer } = filter
  const { searchText } = search

  useEffect(() => {
    dispatch(fetchELearningCourseBatch(uuid))
    dispatch(getEConfiguration())
  }, [])

  useEffect(() => {
    dispatch(fetchDataList('fetch', table, page, filterProp, searchText, uuid))
  }, [limit, order, page, sort])

  const courseUuid = _.get(result, 'eLearningCourse.uuid', '')
  return (
    <StyledContainer>
      <StyledContent isLoading={isLoading}>
        <Box>
          <Typography variant="h4">
            {breadcrumbLearner(uuid, courseUuid)[3].title}
          </Typography>
        </Box>
        <Breadcrumbs menuList={breadcrumbLearner(uuid, courseUuid)} />
        <Header />

        <StyledCard>
          <Table />
        </StyledCard>

        <FilterDrawer open={isFilterDrawer} table={table} />
      </StyledContent>
      <LoadingPage isLoading={isLoading} />
    </StyledContainer>
  )
}
export default Learner

// export const LearnerStatus = ({ row }) => {
//   const theme = useTheme()
//   const status = _.get(row, 'statusLearner', 'CANCELED')
//   const isDisable = status == 'CANCELED' || status == 'CANCEL'
//   const border = `1px solid ${theme?.palette?.text?.lightGray}`
//   const sx = { border: isDisable ? border : 'unset' }
//   return (
//     <TableCell key="statusLearner" sx={{ minWidth: 170 }}>
//       <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//         <Dot sx={sx} status={status} color={checkColorStatus(status, theme)} />
//         <Typography color={isDisable ? 'text.gray' : 'text.primary'}>
//           {learnerStatus[status]}
//         </Typography>
//       </Box>
//     </TableCell>
//   )
// }
