import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TablePagination,
  Checkbox,
  Box,
} from '@mui/material'
import './FixedTable.css'
import { useSelector } from 'react-redux'
import RemoveRedEyeTwoTone from '@mui/icons-material/RemoveRedEyeTwoTone'
import { setOnView } from '../../../../../redux/slices/table'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Truncate from '../../../../../components/redux/Table/Truncate'
import _ from 'lodash'
import {
  convertFloatWithComma,
  convertNumberWithComma,
} from '../../../../../utils/lib'
import {
  StyledCheckBoxCell,
  StyledFullWidth,
  // StyledTableCell,
  StyledTableSortLabel,
} from '../../../../../components/redux/Table/Styled'
import { EnhancedTableToolbar } from '../../../../../components/redux/Table'
import { ROW_PAGE_PER_LIST } from '../../../../../constants/table'
import * as events from '../../../../../components/redux/Table/events'
import { visuallyHidden } from '@mui/utils'

const FixedTable = ({ rowsPerPageOptions = ROW_PAGE_PER_LIST }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    table,
    customStyle,
    // customContainerStyle,
    onDownload,
    handleSearch,
    // filter,
    handleDateSearch,
  } = useSelector((state) => state.table)

  const {
    rows,
    // selected,
    // isCheckBox,
    // headCells,
    isShowPagination,
    allCount,
    rowsPerPage,
    tablePage,
    // sxTable,
  } = table
  // const { quickFilter } = filter
  const viewUrl = ``

  return (
    <StyledFullWidth sx={customStyle}>
      {(onDownload || handleSearch || handleDateSearch) && (
        <EnhancedTableToolbar />
      )}
      <TableContainer component={Paper} className="table-container">
        <Table stickyHeader>
          <EnhancedTableHead />
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell className="fixed-col">{row.no}</TableCell>
                <TableCell className="fixed-col">
                  <Truncate>{'' + _.get(row, `courseNameEN`, '-')}</Truncate>
                </TableCell>
                <TableCell className="fixed-col">
                  <Truncate>{'' + _.get(row, `distribution`, '-')}</Truncate>
                </TableCell>
                <TableCell className="fixed-col">
                  <Truncate>{'' + _.get(row, `station`, '-')}</Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>{'' + _.get(row, `trainingDate`, '-')}</Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertNumberWithComma(row.openRegisterA ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertNumberWithComma(row.openRegisterB ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertFloatWithComma(row.B_A_percent ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertNumberWithComma(row.attendeeC ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertFloatWithComma(row.C_B_percent ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertNumberWithComma(row.notAttendCount ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell sx={{ background: '#1CC54E80' }}>
                  <Truncate>
                    {'' + convertNumberWithComma(row.passCount ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell sx={{ background: '#C9143280' }}>
                  <Truncate>
                    {'' + convertNumberWithComma(row.notPassCount ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertNumberWithComma(row.expenseSummary ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell>
                  <Truncate>
                    {'' + convertNumberWithComma(row.costHead ?? 0)}
                  </Truncate>
                </TableCell>
                <TableCell className="fixed-col">
                  <IconButton
                    data-testid={`btn-view-${index}`}
                    color="primary"
                    component="span"
                    onClick={() => {
                      dispatch(setOnView({ history, viewUrl }))
                    }}
                  >
                    <RemoveRedEyeTwoTone color="action" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isShowPagination && (
        <TablePagination
          data-testid="page-test"
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={allCount}
          rowsPerPage={rowsPerPage.value}
          labelRowsPerPage={
            <Typography variant="caption" color="text.secondary">
              แถวต่อหน้า{'\xA0'} :
            </Typography>
          }
          labelDisplayedRows={() => events.handleLabelDisplay(table)}
          page={tablePage < 0 ? 1 : +tablePage}
          onPageChange={(e, tPage) => dispatch(events.handleChangePage(tPage))}
          onRowsPerPageChange={(event) =>
            dispatch(events.handleChangeRowsPerPage(event))
          }
          sx={{ '& .MuiSvgIcon-root': { top: 'unset !important' } }}
        />
      )}
    </StyledFullWidth>
  )
}

export const EnhancedTableHead = () => {
  const dispatch = useDispatch()
  const { table } = useSelector((state) => state.table)
  const { totalPinned } = useSelector((state) => state.contentForm)
  const { rows, isCheckBox, selected, headCells, defaultSort, order, sort } =
    table
  const rowCount = rows.length
  const selectedCount = selected.length

  return (
    <TableHead sx={{ whiteSpace: 'nowrap' }}>
      <TableRow
        key="head"
        sx={{ '& .MuiTableCell-root': { borderColor: '#F5F5F5' } }}
      >
        {isCheckBox && (
          <StyledCheckBoxCell>
            <Checkbox
              data-testid="select-all-element"
              color="primary"
              indeterminate={selectedCount > 0 && selectedCount < rowCount}
              checked={rowCount > 0 && selectedCount === rowCount}
              onChange={(event) =>
                dispatch(events.handleSelectAllClick(event, rows))
              }
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </StyledCheckBoxCell>
        )}
        {headCells &&
          headCells.map((headCell) => {
            const valueId = headCell.id
            let checkActive = false

            if (defaultSort && valueId === defaultSort.id) {
              checkActive = defaultSort.active
            }

            return (
              <TableCell
                key={valueId}
                align={'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={sort === valueId ? order : false}
                width={headCell.width}
                minWidth={headCell.minWidth}
                sx={{
                  color: headCell.headCellColor,
                  background: headCell.headCellBgColor,
                  minWidth: headCell.minWidth,
                }}
                className={
                  [
                    'no',
                    'courseNameEN',
                    'distribution',
                    'station',
                    'action',
                  ].includes(headCell.id)
                    ? 'fixed-col'
                    : ''
                }
              >
                <StyledTableSortLabel
                  data-testid={`sort-cell-${valueId}`}
                  active={checkActive}
                  direction={sort === valueId ? order : 'asc'}
                  onClick={() =>
                    dispatch(events.handleRequestSort(valueId, table, headCell))
                  }
                  hideSortIcon={headCell.hideSortIcon}
                  headcell={headCell}
                >
                  {headCell.id == 'isPinned'
                    ? `Pin (${totalPinned}/6)`
                    : headCell.label}
                  {sort === valueId && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  )}
                </StyledTableSortLabel>
              </TableCell>
            )
          })}
      </TableRow>
    </TableHead>
  )
}

export default FixedTable
