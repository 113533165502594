import _ from 'lodash'
import { setFieldValue } from '../../../../../redux/slices/inventory/dashboard'
import { toggleFiltersDrawer } from '../../handler/handleClickOpenDrawer'
import dayjs from 'dayjs'
import { fetchOverview } from '../../handler/fetchOverview'
// import { defaultFilter } from './model'
import { callAPI } from '../../../../../utils/lib'
import { stationFilter } from '../../../../../utils/apiPath'

export const handleFiltering = (filter, station) => (dispatch) => {
  const stationList = []
  const user = JSON.parse(localStorage.getItem('user'))
  const stationUser = _.get(user, 'area[0].station')
  const isFilterDate =
    filter.startDateChecked && filter.startDate && filter.endDate ? true : false
  const isFilterCourse = filter.courseChecked && filter.course
  const isFilterInventory = filter.inventoryChecked && filter.inventory
  if (filter.stationChecked) {
    for (const key in filter.station) {
      if (!filter.station[key]) continue
      const item = station.find((e) => e.name === key)
      stationList.push(item)
    }
  }
  const isFilterStation = filter.stationChecked && stationList.length > 0
  let countFilterTotal = 0
  if (isFilterDate) ++countFilterTotal
  if (isFilterCourse) ++countFilterTotal
  if (isFilterStation) ++countFilterTotal
  if (isFilterInventory) ++countFilterTotal

  dispatch(
    setFieldValue({
      key: 'filtersDrawer',
      value: {
        filterTotal: countFilterTotal,
        isFilterDate: isFilterDate,
        isFilterStation: isFilterStation,
      },
    }),
  )
  dispatch(
    setFieldValue({
      key: 'filter',
      value: {
        ...filter,
        station: isFilterStation
          ? stationList
          : [
              {
                label: stationUser.station,
                name: `${stationUser.station.toLowerCase()}Checked`,
                uuid: stationUser.uuid,
              },
            ],
      },
    }),
  )
  toggleFiltersDrawer(false)
  fetchOverview(station)
}

// export const handleChange = ({
//   key,
//   value,
//   filter,
//   setFilter,
//   listKey,
//   isCheck,
// }) => {
//   let objState = {}
//   if (!_.isNil(listKey)) {
//     objState = {
//       ...filter,
//       [listKey]: {
//         ...filter[listKey],
//         [key]: value,
//       },
//     }
//   } else if (isCheck) {
//     const subKey = key.replace('Checked', '')
//     objState = {
//       ...filter,
//       [key]: value,
//       [subKey]: defaultFilter[subKey],
//     }
//   } else {
//     objState = {
//       ...filter,
//       [key]: value,
//     }
//   }
//   setFilter(objState)
// }

export const handleChange = ({ key, value, filter, setFilter, listKey }) => {
  let objState = {
    ...filter,
    [key]: value,
  }
  if (!_.isNil(listKey)) {
    objState = {
      ...filter,
      [listKey]: {
        ...filter[listKey],
        [key]: value,
      },
    }
  }
  setFilter(objState)
}

export const handleSelectDate = (selectedDates, filter, setFilter) => {
  let objState = {
    ...filter,
    startDate: selectedDates.startDate,
    endDate: selectedDates.endDate,
    displayDate: selectedDates.display,
  }
  setFilter(objState)
}

export const handleFetchStation = async (setStation) => {
  const status = ['ACTIVE']
  const body = {
    station: '',
    limit: -1,
    order: 'DESC',
    page: 1,
    sort: 'updatedAt',
    status: status,
    createdBy: '',
    updatedBy: '',
  }
  await callAPI({
    method: 'post',
    url: stationFilter,
    body,
    onSuccess: (data) => {
      const stationList = _.get(data, 'result', []).map((item) => ({
        label: item.station,
        name: _.lowerCase(item.station).replace(' ', '') + 'Checked',
        uuid: item.uuid,
      }))
      setStation(stationList)
    },
  })
}

export const mutateFilterStateToProp = (filterState) => {
  return {
    startDate: filterState.startDateChecked
      ? filterState.startDate
      : dayjs(new Date()).utc(true).startOf('month'),
    endDate: filterState.startDateChecked
      ? filterState.endDate
      : dayjs(new Date()).utc(true),
    inventory: filterState.inventoryChecked ? filterState.inventory : '',
    station: filterState.stationChecked ? filterState.station : [],
    course: filterState.courseChecked ? filterState.course : '',
  }
}

export const mutateFilterPropToBody = (filterProp) => {
  const stationBody = _.get(filterProp, 'station', []).map((item) => item.uuid)
  return {
    startDate: _.isDate(filterProp?.startDate)
      ? dayjs(filterProp?.startDate).utc(true).toDate()
      : dayjs(new Date()).utc(true).startOf('month'),
    endDate: _.isDate(filterProp?.endDate)
      ? dayjs(filterProp?.endDate).utc(true).toDate()
      : dayjs(new Date()).utc(true),
    inventory: _.get(filterProp, 'inventory', ''),
    station: stationBody,
    course: _.get(filterProp, 'course', ''),
  }
}
