import React, { useEffect } from 'react'
import _ from 'lodash'
import useLocalStorage from '@rehooks/local-storage'
// import _ from 'lodash'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import Close from '@mui/icons-material/Close'
// import useLocalStorage from '@rehooks/local-storage'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { StyledHeaderContainer } from '../Styled'
import DropdownButton from './DropdownButton'
import {
  handleAddLearner,
  handleAnnounceLearner,
  handleIneligible,
} from './events'

import { handleSuccess } from '../AddLearnerDialog/events'
import { StyledButton } from './Styled'
import { validatePermission } from '../../../../../../../utils/lib'
import { getCourseFormState } from '../../../events'
import { COURSE_STATUS } from '../../../../../../../constants/eLearning'

const Header = () => {
  const dispatch = useDispatch()
  const { id: uuid } = useParams()
  const [user] = useLocalStorage('user')
  const { selected, rows, isSuccess, result } = useSelector(
    (state) => ({
      selected: state.table.table.selected,
      rows: state.table.table.rows,
      isSuccess: state.manageClassLearner.isSuccess,
      result: state.eLearningCourseBatchDetail.result,
    }),
    shallowEqual,
  )
  const status = _.get(result, 'status', '')
  const isDisable = selected.length === 0
  const data = getCourseFormState()
  const hasEdit = validatePermission({
    user: user,
    moduleType: 'E_LEARNING',
    permission: ['HEAD_ADMIN', 'ADMIN'],
    data,
  })

  useEffect(() => {
    dispatch(handleSuccess(isSuccess))
  }, [isSuccess])

  if (!hasEdit) return <></>

  return (
    <StyledHeaderContainer>
      <StyledButton
        data-testid="btn-announce-learner"
        variant="canceled"
        onClick={() => dispatch(handleAnnounceLearner(uuid, selected, rows))}
        size="m"
        disabled={isDisable}
        startIcon={
          <img
            src={
              isDisable
                ? '/icon/ic_megaphone_gray.svg'
                : '/icon/ic_megaphone_white.svg'
            }
          />
        }
      >
        ประกาศผู้มีสิทธิ์เข้าเรียน
      </StyledButton>

      <Button
        data-testid="btn-eligible"
        variant={isDisable ? 'contained' : 'outlined'}
        size="m"
        startIcon={<Close />}
        disabled={isDisable}
        onClick={() => handleIneligible(uuid, selected, rows)}
      >
        ไม่มีสิทธิ์เข้าเรียน
      </Button>

      <Button
        data-testid="btn-add-learner"
        variant="contained"
        onClick={() => dispatch(handleAddLearner())}
        size="m"
        startIcon={<Add />}
        disabled={addLearnerDisableStatus.includes(status)}
      >
        เพิ่มผู้เรียน
      </Button>

      <DropdownButton />
    </StyledHeaderContainer>
  )
}
export default Header

export const addLearnerDisableStatus = [
  COURSE_STATUS.SUCCESS,
  COURSE_STATUS.COMPLETED,
  COURSE_STATUS.CANCEL,
  COURSE_STATUS.DELETED,
]
