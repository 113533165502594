import React, { useMemo, useRef } from 'react'
import _ from 'lodash'
import ReactQuill, { Quill } from 'react-quill'
import './style.css'
import 'react-quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { getPlainText } from '../../utils/lib'
import { handleInsertImage } from './events'
import { modulesProp, size } from './model'
import { StyledEditorBox } from './Styled'

//Image resize
Quill.register('modules/imageResize', ImageResize)
//Text direction
Quill.register(Quill.import('attributors/style/direction'), true)
//Alignment
Quill.register(Quill.import('attributors/style/align'), true)
// Don't forget corresponding css
const Size = Quill.import('attributors/style/size')
Size.whitelist = size
Quill.register(Size, true)
//Text indent
const Parchment = Quill.import('parchment')

class IndentAttributor extends Parchment.Attributor.Style {
  constructor(attrName, keyName, options = {}) {
    super(attrName, keyName, options)
  }

  add(node, value) {
    if (value <= 0) {
      this.remove(node)
      return true
    }
    return super.add(node, `${value}em`)
  }

  value(node) {
    return parseFloat(super.value(node)) || undefined
  }
}
const pixelLevels = _.range(20)
export const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: pixelLevels.map((value) => `${value}em`),
})
Quill.register(IndentStyle, true)

const QuillEditor = ({
  id,
  boxSx,
  required,
  disabled,
  readOnly,
  isTextLefe,
  labelText,
  value,
  textError,
  textInfo,
  showMaxLength,
  onChange,
}) => {
  const plainText = getPlainText(value)
  const quillRef = useRef()
  const modules = useMemo(
    () => ({
      ...modulesProp,
      toolbar: {
        ...modulesProp.toolbar,
        handlers: { image: () => handleInsertImage(quillRef) },
      },
    }),
    [],
  )

  return (
    <StyledEditorBox
      sx={{ ...boxSx }}
      textError={textError}
      disabled={disabled}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex">
          <Typography
            variant="body2"
            color={disabled ? 'text.gray' : 'text.secondary'}
          >
            {labelText}
          </Typography>
          {required && (
            <Typography
              sx={{ lineHeight: 1, ml: 0.5 }}
              color={disabled ? 'text.gray' : 'error'}
            >
              *
            </Typography>
          )}
        </Box>
      </Box>

      <ReactQuill
        bounds={`[data-text-editor="form-editor"]`}
        ref={quillRef}
        theme="snow"
        id={id}
        readOnly={readOnly || disabled}
        modules={modules}
        onChange={onChange}
        value={value}
      />

      <Box
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: isTextLefe ? 'flex-start' : 'space-between',
        }}
      >
        <Box>
          {!textError && textInfo && textInfo.length > 0 && (
            <Typography variant="body2" color="text.lightGray">
              {textInfo}
            </Typography>
          )}
          {textError && textError.length > 0 && (
            <Typography variant="body2" color="error">
              {textError}
            </Typography>
          )}
        </Box>
        {showMaxLength > 0 && (
          <Typography
            sx={{
              height: '25px',

              textAlign: 'end',
              color: plainText.length > showMaxLength ? 'error.main' : 'unset',
            }}
            variant="body2"
          >
            {`${plainText.length}/${showMaxLength}`}
          </Typography>
        )}
      </Box>
    </StyledEditorBox>
  )
}

export default QuillEditor
