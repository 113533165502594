import _ from 'lodash'
import { store } from '../../../../../App'
import { validateOneLink } from '../../../events'
import * as yup from 'yup'
import { validateVideo } from '../Handler/validateVideo'

export const validationSchema = yup.object({
  name: yup
    .string()
    .required('กรุณากรอกชื่อ Collection')
    .max(255, 'กรุณาระบุชื่อ Collection ภายใน 255 ตัวอักษร'),
  nameForBanner: yup
    .string()
    .required('กรุณากรอกชื่อหัวข้อบน Banner')
    .max(255, 'กรุณาระบุชื่อหัวข้อบน Banner ภายใน 255 ตัวอักษร'),
  detailForBanner: yup
    .string()
    .nullable()
    .max(255, 'กรุณาระบุรายละเอียดบน Banner ภายใน 255 ตัวอักษร'),
  description: yup
    .string()
    .nullable()
    .max(3000, 'กรุณาระบุรายละเอียด ภายใน 3000 ตัวอักษร'),
  coverImage: yup.string().required('กรุณาอัพโหลดรูปภาพหน้าปก'),
  linkExampleVideo: yup
    .string()
    .nullable()
    .test(
      'validate-link',
      'Link สำหรับวิดีโอตัวอย่างต้องมากจาก YouTube หรือ Vimeo เท่านั้น',
      (value) => _.isEmpty(value) || validateVideo(value),
    )
    .test(
      'one-link',
      'Link สำหรับวิดีโอตัวอย่างกรอกได้ไม่เกิน 1 Link',
      (value) => validateOneLink(value),
    )
    .max(3000, 'Link สำหรับวิดีโอตัวอย่างกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  remark: yup
    .string()
    .nullable()
    .max(3000, 'กรุณากรอกหมายเหตุ ภายใน 3000 ตัวอักษร'),
  contents: yup
    .array()
    .test(
      'contentsLengthCheck',
      'กรุณากรอกรายการใน Collection อย่างน้อย 2 รายการ',
      (arr) => {
        return arr.length >= 2
      },
    )
    .of(
      yup.object().shape({
        content: yup
          .object()
          .nullable()
          .test(
            'isContentEmpty',
            'กรุณาเลือกหลักสูตรหรือ LearningPath',
            (obj) => {
              return !_.isEmpty(obj)
            },
          ),
      }),
    ),
  periodStart: yup
    .string()
    .required('กรุณากรอก วันเริ่มต้น')
    .test('test pastdate', 'วันเริ่มต้น ต้องไม่เป็นวันในอดีต', function (date) {
      if (
        Date.parse(store.getState().crud.formData.oldPeriodStart) ===
        Date.parse(date)
      )
        return true
      return Date.parse(date) >= new Date().setHours(0, 0, 0, 0)
    }),
  setting: yup.object().shape({
    distribution: yup.array().min(1, 'กรุณากรอกช่องทางการจำหน่าย'),
    remark: yup.string().max(3000, 'กรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
  levelOfLearner: yup
    .array()
    .min(1, 'กรุณากรอกระดับของผู้เรียน อย่างน้อย 1 รายการ')
    .nullable()
    .required('กรุณากรอกระดับของผู้เรียน  อย่างน้อย 1 รายการ'),
  acquiredSkill: yup
    .array()
    .min(1, 'กรุณากรอกระดับหลักสูตร อย่างน้อย 1 รายการ')
    .nullable()
    .required('กรุณากรอกระดับหลักสูตร อย่างน้อย 1 รายการ'),
  productType: yup
    .array()
    .min(1, 'กรุณากรอกทักษะที่ได้จากหลักสูตร อย่างน้อย 1 รายการ')
    .nullable()
    .required('กรุณากรอกทักษะที่ได้จากหลักสูตร อย่างน้อย 1 รายการ'),
  dueDateType: yup.string(),
  dueDate: yup
    .string()
    .when('dueDateType', (dueDateType, schema) => {
      if (dueDateType === 'SPECIFIC_DATE') {
        return schema.nullable().required('กรุณากรอกวันที่')
      }
    })
    .nullable(),
})
