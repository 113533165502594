// import { validatePermission } from '../../../../../utils/lib'

export const headerCells = () => {
  //const user = JSON.parse(localStorage.getItem('user'))
  // const hasPermissionDelete = validatePermission({
  //   user: user,
  //   moduleType: 'MASTER_DATA',
  //   permission: ['DELETE'],
  // })

  return [
    {
      id: 'id',
      label: 'ลำดับ',
      disablePadding: false,
      width: '20px',
    },
    {
      id: 'ticketID',
      label: 'Ticket ID',
      disablePadding: false,
    },
    {
      id: 'courseName',
      label: 'ชื่อคอร์ส',
      disablePadding: false,
    },
    {
      id: 'trainingDate',
      label: 'วันที่อบรม',
      disablePadding: false,
    },
    {
      id: 'createdBy',
      label: 'สร้างโดย',
      disablePadding: false,
    },
    {
      id: 'createdAt',
      label: 'วันที่สร้าง',
      disablePadding: false,
    },
    {
      id: 'status',
      label: 'สถานะ',
      disablePadding: false,
    },
    {
      id: 'dueDate',
      label: 'กำหนดคืน',
      disablePadding: false,
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'คำสั่ง',
      width: '90px',
      hideSortIcon: true,
      hideDelete: true,
      hideEdit: true,
      viewPath: ``,
      viewId: 'codeId',
    },
  ]
}
