import { Box, Typography } from '@mui/material'
import React from 'react'
import { StyledBox, Column, Row } from '../Styled'
import FinancialRow from './FinancialRow'
import HorizontalBarChart from 'src/components/Chart/HorizontalBarChart'

const DetailsFinancialBox = ({
  title,
  budget,
  actual,
  remaining,
  idx,
  colorActual,
  colorRemaining,
  bgColor,
  percent,
  budgetExceeded,
}) => {
  return (
    <StyledBox width="100%">
      <Typography variant="body1">{title}</Typography>
      <Box>
        <Typography variant="body2b" display="inline">
          Budget
        </Typography>{' '}
        <Typography variant="body2" display="inline">
          {budget.toLocaleString()} ฿
        </Typography>
      </Box>
      <Row gap={3} width="100%">
        <Column width="30%">
          <FinancialRow
            label="Actual"
            amount={actual}
            color={colorActual}
            budgetExceeded={budgetExceeded}
          />
          <FinancialRow
            label="Remaining"
            amount={remaining}
            color={colorRemaining}
            budgetExceeded={budgetExceeded}
          />
        </Column>

        <HorizontalBarChart
          width="70%"
          height={60}
          labels={['']}
          dataSet={{
            backgroundColor: bgColor,
            data: percent,
          }}
          eleId={`summaryBudgetChart-${idx}`}
        />
      </Row>
    </StyledBox>
  )
}

export default DetailsFinancialBox
