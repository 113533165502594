import Select from '../../../../../components/Input/Select'
import { timePeriodOptions } from '../../models/timePeriodOptions'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { updateDashboardByKey } from '../../handler/updateDashboardByKey'
import { Row } from '../Styled'
import Autocomplete from '../../../../../components/Input/Autocomplete'
import { yearBudgetOptions } from 'src/modules/BudgetManagement/Form/model/yearBudgetOptions'
import { TextField, Box, Button } from '@mui/material'
import DateRangeInput from '../../../../../components/DateRangeInput'
import { setIsOpenFilterDrawer } from 'src/redux/slices/budgetManagement'
import { FilterListOutlined } from '@mui/icons-material'
import dayjs from 'dayjs'

const TimePeriodSelector = () => {
  const dispatch = useDispatch()
  const { timePeriod, yearBudget, dateRange, tabActive } = useSelector(
    (state) => ({
      timePeriod: state.budgetManagement.dashboard.timePeriod,
      yearBudget: state.budgetManagement.dashboard.yearBudget,
      dateRange: state.budgetManagement.dashboard.dateRange,
      tabActive: state.budgetManagement.dashboard.tabActive,
    }),
    shallowEqual,
  )

  return (
    <Row justifyContent="space-between">
      <Row gap={1.5}>
        <Select
          boxSx={{ width: 150 }}
          textErrorSx={{ mb: 0 }}
          dataTestid="select-timePeriod"
          id="timePeriod"
          name="timePeriod"
          options={timePeriodOptions}
          value={!_.isNil(timePeriod) ? timePeriod : ''}
          defaultValue={timePeriod}
          handleChange={(e) => {
            const value = _.get(e, 'target.value', '')
            updateDashboardByKey({ key: 'timePeriod', value })
          }}
        />
        <Box width={250}>
          {timePeriod === 'yearly' ? (
            <Autocomplete
              boxSx={{ width: 150 }}
              errorSx={{ mb: 0 }}
              id="yearBudget"
              name="yearBudget"
              isHideSort
              options={yearBudgetOptions()}
              value={yearBudget}
              defaultValue={{
                value: yearBudget,
                label: yearBudget,
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField placeholder={'กรุณาเลือก'} {...params} />
              )}
              handleChange={(e, element) =>
                updateDashboardByKey({
                  key: 'yearBudget',
                  value: _.get(element, 'value', ''),
                })
              }
            />
          ) : (
            <DateRangeInput
              placeholder="เลือกวันที่"
              dateState={{
                startDate: !dateRange?.startDate
                  ? new Date()
                  : dateRange?.startDate,
                endDate: !dateRange?.endDate ? new Date() : dateRange?.endDate,
                display: false,
                key: 'selection',
              }}
              onChange={(selectedDates) => {
                const startDate = dayjs(
                  _.get(selectedDates, 'startDate', ''),
                ).format('YYYY-MM-DD')
                const endDate = dayjs(
                  _.get(selectedDates, 'endDate', ''),
                ).format('YYYY-MM-DD')

                updateDashboardByKey({
                  key: 'dateRange',
                  value: { startDate, endDate },
                })
              }}
            />
          )}
        </Box>
      </Row>
      {tabActive !== 0 && (
        <Button
          data-testid={'btn-filter'}
          sx={{ height: 40, p: 1 }}
          variant="text"
          startIcon={<FilterListOutlined />}
          onClick={() => dispatch(setIsOpenFilterDrawer(true))}
        >
          ตัวกรอง
          {/* {filters.length > 0 && `(${filters.length})`} */}
        </Button>
      )}
    </Row>
  )
}

export default TimePeriodSelector
