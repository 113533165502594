import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import NormalTable from '../../../../../components/Table/NormalTable/NormalTable'
import _ from 'lodash'
import CourseDrawer from './CourseDrawer'
import {
  startLoading,
  stopLoading,
  setPagination,
  setSearch,
} from '../../../../../redux/slices/manage/agentProfile'
import SearchCustom from '../Search'
import { BoxHeder, BoxGroup } from './Styled'
import { fetchCourse } from './events'
import { onSort } from '../../../../../utils/lib'
import { headerCells } from './headerCells'
import { StyledButton } from '../Styled'
import { ChevronRight } from '@mui/icons-material'

const WaitingTable = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const { id: uuid } = useParams()
  const {
    search = { courseSearch: '', examSearch: '' },
    pagination,
    data,
  } = useSelector(
    (state) => ({
      search: state.inventoryDashboard?.waitingTable?.search,
      pagination: state.inventoryDashboard?.waitingTable?.pagination,
      data: state.inventoryDashboard?.waitingTable?.initialState,
    }),
    shallowEqual,
  )
  const { courseSearch, examSearch } = search
  const isSwitch = _.get(data, 'isSwitch', false)
  const [head, setHead] = useState(headerCells())

  const onRequestSort = (valueId, headCell) => {
    const { head, order } = onSort(valueId, headCell)
    const sort = _.isEmpty(order) ? 'updatedAt' : valueId
    const orderBy = _.isEmpty(order) ? 'DESC' : String(order).toUpperCase()
    dispatch(
      setSearch({
        key: 'orderCourseBy',
        value: orderBy,
      }),
    )
    dispatch(
      setSearch({
        key: 'sortCourseBy',
        value: sort,
      }),
    )
    dispatch(
      fetchCourse({
        ...pagination,
        agentUuid: uuid,
        order: orderBy,
        sort: sort,
        examSearch: _.defaultTo(examSearch, ''),
        courseSearch: _.defaultTo(courseSearch, ''),
        courseOriginal: isSwitch,
      }),
    )
    setHead(head)
  }

  useEffect(() => {}, [])

  return (
    <>
      <Card sx={{ pb: 3 }}>
        <BoxHeder>
          <BoxGroup>
            <Typography
              variant="h6"
              fontWeight={'light'}
              sx={{ display: 'flex' }}
            >
              รายการทรัพย์สิน
              <Typography variant="h6"> รอคืน</Typography>
            </Typography>
            <StyledButton
              color="text.lightGray"
              size="small"
              background="#4976BA14"
              marginLeft={''}
              onClick={() => {}}
            >
              <ChevronRight fontSize="small" />
            </StyledButton>
          </BoxGroup>
          <BoxGroup>
            <SearchCustom searchKey="courseSearch" />
          </BoxGroup>
        </BoxHeder>
        <NormalTable
          headCells={head}
          listRows={_.get(data, 'course.result', [])}
          totalRows={_.get(data, 'course.totalCount', 0)}
          showPagination={false}
          isHiddenOptions
          startLoading={startLoading}
          stopLoading={stopLoading}
          onRequestSort={onRequestSort}
          handlePagination={(events) =>
            dispatch(
              setPagination({
                key: 'coursePage',
                value: events.page,
              }),
            )
          }
        />
      </Card>
      <CourseDrawer isOpen={isOpen} onCloseDrawer={() => setIsOpen(false)} />
    </>
  )
}

export default WaitingTable
