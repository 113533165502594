import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './model'
import _ from 'lodash'
//import { filterinventoryDashboard } from '../../../services/inventoryDashboard'

const inventoryDashboard = createSlice({
  name: 'inventoryDashboard',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true
    },
    stopLoading: (state) => {
      state.isLoading = false
    },
    setIsOpenFilter: (state, { payload }) => {
      state.isOpenFilter = payload
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setType: (state, { payload }) => {
      state.type = payload
    },
    openTableDrawer: (state, { payload }) => {
      const { header, drawerType, courseType, score } = payload
      state.tableDrawer.isOpen = true
      state.tableDrawer.header = header
      state.tableDrawer.drawerType = drawerType
      state.tableDrawer.courseType = courseType
      state.tableDrawer.score = score
    },
    closeTableDrawer: (state) => {
      state.tableDrawer.isOpen = false
    },
    openEnrollDrawer: (state, { payload }) => {
      const { header, uuid } = payload
      state.enrollDrawer.isOpen = true
      state.enrollDrawer.header = header
      state.enrollDrawer.uuid = uuid
    },
    closeEnrollDrawer: (state) => {
      state.enrollDrawer.isOpen = false
    },
    setFilterMemo: (state, { payload }) => {
      state.filterMemo = payload
    },
    resetFilter: (state, { payload }) => {
      state.filterMemo = payload
      state.filter = initialState.filter
    },
    setFieldValue: (state, { payload }) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setIsOpenFilter,
  setFilter,
  setType,
  openTableDrawer,
  closeTableDrawer,
  openEnrollDrawer,
  closeEnrollDrawer,
  setFilterMemo,
  resetFilter,
  setFieldValue,
} = inventoryDashboard.actions

export default inventoryDashboard.reducer
