import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import Header from './components/Header'
import Content from './components/Content'
import { useEffect } from 'react'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import { initialize } from './handler/initialize'
import FilterDrawer from './components/FilterDrawer'

const BudgetManagementDashboard = () => {
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.crud.isLoading,
    }),
    shallowEqual,
  )
  useEffect(initialize, [])

  return (
    <Box px={2} mx={3}>
      <Header />
      <Content />

      <FilterDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}

export default BudgetManagementDashboard
