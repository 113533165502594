export const timePeriodOptions = [
  {
    label: 'รายปี',
    value: 'yearly',
  },
  {
    label: 'กำหนดระยะเวลา',
    value: 'custom',
  },
]
