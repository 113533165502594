import * as yup from 'yup'
import _ from 'lodash'
import dayjs from 'dayjs'
import { coursePrerequisiteSchema } from '../../../../modules/CourseSetting/FormSchema'
import { COURSE_STATUS, COURSE_TYPE } from '../../../../constants/eLearning'
import { validateVideo } from '../../Module/Form/TypeContent/EmbedContent/events'
import { validateMinDate } from '../../components/events'
import { validateOneLink } from '../../events'
import { getStateValue } from './events'

export const lessonSchema = yup.array().of(
  yup.object().shape({
    name: yup
      .string()
      .test(
        'required',
        'กรุณากรอกชื่อบทเรียน',
        (value, { from }) => isDraft(from[1]?.value) || value,
      )
      .max(255, 'ชื่อบทเรียนกรอกได้ไม่เกิน 255 ตัวอักษร'),
    description: yup.string().nullable(),
    eLearningLessonRefModule: yup.array().of(
      yup.object().shape({
        moduleType: yup
          .string()
          .test(
            'required',
            'กรุณาเลือกประเภทโมดูล',
            (value, { from }) => isDraft(from[2]?.value) || value,
          ),
        eLearningModule: yup.object().shape({
          uuid: yup
            .string()
            .test(
              'required',
              'กรุณาเลือกโมดูล',
              (value, { from }) => isDraft(from[3]?.value) || value,
            ),
        }),
      }),
    ),
  }),
)

export const settingSchema = yup.object().shape({
  courseType: yup.string(),
  assignees: yup
    .array()
    .test(
      'validate-assignee',
      'กรุณาใส่ข้อมูลวิทยากร และ Admin อย่างน้อยอย่างละ 1 ท่าน',
      (value, { from }) => {
        if (isDraft(from[1]?.value)) return true
        const courseType = getStateValue('courseType')
        if (courseType === COURSE_TYPE.GENERAL) return true
        return !_.isEmpty(value)
      },
    ),
  tag: yup
    .array()
    .test(
      'validate-tag',
      'กรุณาเลือก Tag อย่างน้อย 1 Tag',
      (value, { from }) => {
        if (isDraft(from[1]?.value)) return true
        const courseType = getStateValue('courseType')
        if (courseType === COURSE_TYPE.GENERAL) return true
        return !_.isEmpty(value)
      },
    ),
  distribution: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ช่องทางการจัดจำหน่าย อย่างน้อย 1 ช่อง',
      (value, { from }) => isDraft(from[1]?.value) || !_.isEmpty(value),
    ),
  collectHours: yup.object().shape({
    isCollect: yup.boolean(),
    hours: yup
      .string()
      .nullable()
      .test('test-required', 'กรุณากรอกข้อมูล', (value, { parent }) => {
        if (!parent.isCollect) return true
        return value
      }),
    description: yup
      .string()
      .nullable()
      .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
  sellCourse: yup.object().shape({
    isSell: yup.boolean(),
    cost: yup
      .string()
      .nullable()
      .test('test-required', 'กรุณากรอกข้อมูล', (value, context) => {
        const parent = _.get(context, 'parent', {})
        if (!parent.isSell) return true
        return value
      }),
    description: yup
      .string()
      .nullable()
      .max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  }),
  remark: yup.string().max(3000, 'Remark กรอกได้ไม่เกิน 3000 ตัวอักษร'),
  isSkipAllowed: yup.boolean(),
})

export const schema = yup.object({
  courseCode: yup.string().nullable(),
  courseType: yup.string(),
  name: yup
    .string()
    .required('กรุณากรอกชื่อหลักสูตร')
    .max(255, 'ชื่อหลักสูตรกรอกได้ไม่เกิน 255 ตัวอักษร'),
  nameForLearner: yup
    .string()
    .max(255, 'ชื่อหลักสูตร (สำหรับ Learner) กรอกได้ไม่เกิน 255 ตัวอักษร'),
  subject: yup.string().max(255, 'วิชากรอกได้ไม่เกิน 255 ตัวอักษร'),
  description: yup.string().max(3000, 'รายละเอียดกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  coverImage: yup
    .string()
    .test(
      'required',
      'กรุณาอัปโหลดรูปภาพหน้าปก',
      (value, { parent }) => isDraft(parent) || value,
    ),
  linkExampleVideo: yup
    .string()
    .test(
      'validate-link',
      'Link สำหรับวิดีโอตัวอย่างต้องมากจาก YouTube หรือ Vimeo เท่านั้น',
      (value, { parent }) =>
        isDraft(parent) || _.isEmpty(value) || validateVideo(value),
    )
    .test(
      'one-link',
      'Link สำหรับวิดีโอตัวอย่างกรอกได้ไม่เกิน 1 Link',
      (value) => validateOneLink(value),
    )
    .max(3000, 'Link สำหรับวิดีโอตัวอย่างกรอกได้ไม่เกิน 3000 ตัวอักษร'),
  remark: yup.string().max(3000, 'Remark กรอกได้ไม่เกิน 3000 ตัวอักษร'),
  status: yup.string(),
  point: yup.string().when('pointStatus', {
    is: 'ACTIVE',
    then: yup.string().when('pointType', {
      is: 'CUSTOM',
      then: yup.string().required('กรุณากรอกข้อมูลในช่องนี้'),
    }),
  }),
  learningPointUuid: yup.string().when('pointStatus', {
    is: 'ACTIVE',
    then: yup.string().when('pointType', {
      is: 'LINK',
      then: yup.string().required('กรุณากรอกข้อมูลในช่องนี้'),
    }),
  }),
  eLearningCourseLesson: lessonSchema,
  totalHour: yup
    .string()
    .test(
      'required',
      'กรุณากรอกจำนวนชั่วโมงของหลักสูตร',
      (value, { parent }) => isDraft(parent) || value,
    ),
  totalMinute: yup
    .string()
    .test(
      'required',
      'กรุณาเลือกจำนวนนาที',
      (value, { parent }) => isDraft(parent) || value,
    ),
  periodStart: yup
    .string()
    .required('กรุณาเลือกวันเริ่มต้น')
    .test(
      'validate-date',
      'กรุณากรอกรูปแบบวันที่ให้ถูกต้อง',
      (value) => _.isEmpty(value) || dayjs(value).isValid(),
    )
    .test(
      'validate-date',
      'ไม่สามารถใส่วันเริ่มต้นย้อนหลังวันปัจจุบันได้',
      (value) => validateMinDate(value, 'eLearningCourseForm.oldPeriodStart'),
    ),
  dueDateType: yup
    .string()
    .test(
      'required',
      'กรุณาเลือก',
      (value, { parent }) => isDraft(parent) || value,
    ),
  dueDate: yup
    .string()
    .nullable()
    .test('require-due-date', 'กรุณาเลือกวันที่', (value, { parent }) => {
      if (isDraft(parent)) return true
      if (parent?.dueDateType === 'NONE') return true
      return !_.isEmpty(value)
    })
    .test(
      'validate-date',
      'กรุณากรอกรูปแบบวันที่ให้ถูกต้อง',
      (value, { parent }) => {
        if (isDraft(parent)) return true
        if (parent?.dueDateType === 'NONE') return true
        return _.isEmpty(value) || dayjs(value).isValid()
      },
    )
    .test(
      'validate-date',
      'ไม่สามารถใส่วันย้อนหลังวันปัจจุบันได้',
      (value, { parent }) => {
        if (isDraft(parent)) return true
        if (parent?.dueDateType === 'NONE') return true
        return validateMinDate(value, 'eLearningCourseForm.oldDueDate')
      },
    ),
  courseAccessExpiration: yup.string().nullable(),
  timeSpanType: yup
    .string()
    .nullable()
    .test('require-time-span', 'กรุณากรอกข้อมูล', (value, { parent }) => {
      if (isDraft(parent)) return true
      if (parent?.courseAccessExpiration !== 'TIME_SPAN') return true
      return !_.isEmpty(value)
    }),
  timeSpan: yup
    .string()
    .nullable()
    .test('require-time-span', 'กรุณากรอกข้อมูล', (value, { parent }) => {
      if (isDraft(parent)) return true
      if (parent?.courseAccessExpiration !== 'TIME_SPAN') return true
      return !_.isEmpty(value)
    }),
  levelOfLearner: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ระดับผู้เรียน อย่างน้อย 1 ช่อง',
      (value, { parent }) => isDraft(parent) || !_.isEmpty(value),
    ),
  acquiredSkill: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ระดับหลักสูตรอย่างน้อย อย่างน้อย 1 ช่อง',
      (value, { parent }) => isDraft(parent) || !_.isEmpty(value),
    ),
  productType: yup
    .array()
    .test(
      'min',
      'กรุณาเลือก ทักษะที่ได้จากหลักสูตร อย่างน้อย 1 ช่อง',
      (value, { parent }) => isDraft(parent) || !_.isEmpty(value),
    ),
  coursePrerequisite: coursePrerequisiteSchema,
  setting: settingSchema,
})

export const schemaDraft = yup.object({
  courseCode: yup.string().nullable(),
  courseType: yup.string(),
  name: yup
    .string()
    .required('กรุณากรอกชื่อหลักสูตร')
    .max(255, 'ชื่อหลักสูตรกรอกได้ไม่เกิน 255 ตัวอักษร'),
  status: yup.string(),
  periodStart: yup
    .string()
    .required('กรุณาเลือกวันเริ่มต้น')
    .test(
      'validate-date',
      'กรุณากรอกรูปแบบวันที่ให้ถูกต้อง',
      (value) => _.isEmpty(value) || dayjs(value).isValid(),
    )
    .test(
      'validate-date',
      'ไม่สามารถใส่วันเริ่มต้นย้อนหลังวันปัจจุบันได้',
      (value) => validateMinDate(value, 'eLearningCourseForm.oldPeriodStart'),
    ),
})

export const isDraft = (parent) =>
  _.get(parent, 'status', '') === COURSE_STATUS.DRAFT
