import _ from 'lodash'

export const getMonths = () => {
  const monthsInEN = new Array(12)
    .fill(null)
    .map((_, idx) =>
      new Date(2024, idx).toLocaleString('en-US', { month: 'long' }),
    )

  const monthsInTH = new Array(12)
    .fill(null)
    .map((_, idx) =>
      new Date(2024, idx).toLocaleString('th-TH', { month: 'long' }),
    )

  return _.map(monthsInEN, (month, idx) => ({
    monthEN: month,
    monthTH: monthsInTH[idx],
  }))
}
