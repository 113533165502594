import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/crud'
import { drawerType } from '../enums/drawerEnum'
// import {
//   examinerDrawerEnum,
//   rankingDrawerEnum,
//   scheduleDrawerEnum,
// } from '../enums/drawerEnum'
// import { resetRowsPerPage, setSearchText } from '../../../../redux/slices/table'

export const handleClickOpenDrawer = (key = '', data = null) => {
  console.log('key', key, 'data', data)
  let classCount = 0
  let type = key.includes('classDrawer')
    ? drawerType.ALL_CLASS
    : drawerType.TOP_LIST
  if (key === 'classDrawerAll') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด`)
  } else if (key === 'classDrawerAgency') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (Agency)`)
  } else if (key === 'classDrawerBanca') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (BANCA)`)
  } else if (key === 'classDrawerKtb') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (KTB)`)
  } else if (key === 'classDrawerOther') {
    setDetailDrawerTitle(`จำนวน Class ทั้งหมด (Other)`)
  } else if (key === 'rankingCourse') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (รวม)`)
  } else if (key === 'rankingBudget') {
    setDetailDrawerTitle(`หลักสูตรค่าใช้จ่ายสูงสุด`)
  } else if (key === 'rankingCourseAgency') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (Agency)`)
  } else if (key === 'rankingCourseBanca') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (BANCA)`)
  } else if (key === 'rankingCourseKtb') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (KTB)`)
  } else if (key === 'rankingCourseOther') {
    setDetailDrawerTitle(`หลักสูตร จัดสอบ มากที่สุด (Other)`)
  }

  togglDetailDrawer(true)
  setDetailDrawerType(type)
  setDetailDrawerClassCount(classCount)
}

export const toggleFiltersDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.filtersDrawer.isOpen`,
      value: value,
    }),
  )
}

export const togglDetailDrawer = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.isOpen`,
      value: value,
    }),
  )
}

export const setDetailDrawerTitle = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.title`,
      value: value,
    }),
  )
}

export const setDetailDrawerType = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.type`,
      value: value,
    }),
  )
}

export const setDetailDrawerClassCount = (value) => {
  store.dispatch(
    setFieldValue({
      key: `mixModuleDashboard.detailDrawer.classCount`,
      value: value,
    }),
  )
}
