import { Typography } from '@mui/material'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Row, Column } from '../Styled'
import DetailsFinancialBox from './DetailsFinancialBox'
import _ from 'lodash'

const SummarySection = () => {
  const { summary } = useSelector(
    (state) => ({
      summary: state.budgetManagement.dashboard.summary,
    }),
    shallowEqual,
  )

  return (
    <Column gap={1.5}>
      <Typography variant="h6">Full Year</Typography>
      <Row gap={2}>
        {_.map(summary, (sum, idx) => (
          <DetailsFinancialBox idx={idx} key={idx} {...sum} />
        ))}
      </Row>
    </Column>
  )
}

export default SummarySection
