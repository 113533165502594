export const initialState = {
  error: null,
  isLoading: false,
  dashboard: {
    isLoading: false,
    tabActive: 0,
    isOpenFilterDrawer: false,
    timePeriod: 'yearly',
    yearBudget: 2024,
    dateRange: {},
    summary: [],
    monthlySummary: [],
    name: '',
    filters: {
      costCenterChecked: false,
      accountCodeChecked: false,
      staffTypeChecked: false,
      staffProfileChecked: false,
      categoryChecked: false,
      costCenter: [],
      accountCode: [],
      staffType: [],
      staffProfile: [],
      category: [],
      filterTotal: 0,
    },
    displayFilter: {},
    filtersList: [],
  },
}

export const filtersListByCostCenter = [
  {
    label: 'Cost center / Department ที่แสดง',
    nameCheckBox: 'costCenterChecked',
    nameInput: 'costCenter',
    type: 'select',
    placeholder: 'เลือก Cost center',
  },
  {
    label: 'Account code (Old/New)',
    nameCheckBox: 'accountCodeChecked',
    nameInput: 'accountCode',
    type: 'select',
    placeholder: 'เลือก Account code',
  },
  {
    label: 'Staff type',
    nameCheckBox: 'staffTypeChecked',
    nameInput: 'staffType',
    type: 'select',
    placeholder: 'เลือก Staff type',
  },
  {
    label: 'Staff profile',
    nameCheckBox: 'staffProfileChecked',
    nameInput: 'staffProfile',
    type: 'select',
    placeholder: 'เลือก Staff profile',
  },
  {
    label: 'Categories',
    nameCheckBox: 'categoryChecked',
    nameInput: 'category',
    type: 'select',
    placeholder: 'เลือก Categories',
  },
]
