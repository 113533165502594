import { Box, Typography } from '@mui/material'
import React from 'react'
import { breadcrumb } from '../../models/breadcrumb'
import { breadcrumbByDepartment } from '../../models/breadcrumbByDepartment'
import Breadcrumbs from '../../../../../components/Breadcrumbs'
import BasicTabs from 'src/components/BasicTabs'
import { tabsList } from '../../models/tabsList'
import { shallowEqual, useSelector } from 'react-redux'
import { handleChangeTab } from '../../handler/handleChangeTab'
import { useParams } from 'react-router-dom'

const Header = () => {
  const { uuid } = useParams()

  const { tabActive } = useSelector(
    (state) => ({
      tabActive: state.budgetManagement.dashboard.tabActive,
    }),
    shallowEqual,
  )

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box>
        <Typography variant="h4">Expense Dashboard</Typography>
        <Breadcrumbs
          menuList={uuid ? breadcrumbByDepartment('test') : breadcrumb}
        />
      </Box>
      <BasicTabs
        tabActive={tabActive}
        tabsList={tabsList}
        handleChangeTab={handleChangeTab}
      />
    </Box>
  )
}

export default Header
