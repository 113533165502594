import _ from 'lodash'
import {
  startLoading,
  stopLoading,
  setInitialTable,
  resetPage,
  resetTablePage,
  setSearchText,
} from '../../../../../redux/slices/table2'
import { getInventoryDashboard } from '../../../../../utils/apiPath'
import callAxios from '../../../../../utils/baseService'
import { store } from '../../../../../App'
import { mutateFilterPropToBody } from '../FilterDrawer/event'

export const headCellsClass = () => [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: '',
    label: 'หมวดหมู่',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'fullName',
    label: 'หมวดหมู่ย่อย',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'trainingDateTime',
    label: 'คงเหลือ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'courseNameENDrawer',
    label: 'พร้อมใช้งาน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'testTypeDrawer',
    label: 'มูลค่ารวม',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'ActionViewUserProfile',
    label: '',
    disablePadding: false,
    hideDelete: true,
    hideEdit: true,
    hideSortIcon: true,
    minWidth: '40px',
    isETest: true,
  },
]

export const headCellsRanking = () => [
  {
    id: 'no',
    label: 'ลำดับ',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'agentCode',
    label: 'ชื่อทรัพย์สิน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: 'fullName',
    label: 'จำนวน',
    disablePadding: false,
    hideSortIcon: true,
  },
  {
    id: '',
    label: 'มูลค่า',
    disablePadding: false,
    hideSortIcon: true,
  },
]

export const fetchData = (courseType, tableProps) => async (dispatch) => {
  const { search, realPage, order, sort, filter } = store.getState().table2
  const { searchText } = search

  const mutateBody = {
    ...mutateFilterPropToBody(filter),
    name: searchText,
  }

  const body = {
    limit: _.get(tableProps, 'limit', 0),
    page: realPage,
    order: order,
    sort: sort,
    quickSearch: searchText,
    ...mutateBody,
    createdBy: _.get(filter, 'createdBy', ''),
    updatedBy: _.get(filter, 'updatedBy', ''),
  }

  let headCells = headCellsClass()
  if (courseType === 'rankingTable') {
    headCells = headCellsRanking()
  }

  dispatch(startLoading())
  await callAxios.post(getInventoryDashboard, body).then((res) => {
    const row = _.get(res, 'data.result', []).map((item) => {
      return {
        ...item,
      }
    })

    const handleSearch = () => {
      if (courseType === 'stock') {
        return (text) => {
          dispatch(handleQuickSearch(tableProps, text))
        }
      } else return undefined
    }

    dispatch(
      setInitialTable({
        rows: row,
        isCheckBox: false,
        allCount: row.length,
        headCells: headCells,
        searchKey: 'name',
        hideFilter: true,
        rowsPerPage: 10,
        handleSearch: handleSearch(),
      }),
    )
  })
  dispatch(stopLoading())
}

export const handleQuickSearch = (table, keyword) => (dispatch) => {
  dispatch(setSearchText(keyword))
  dispatch(resetTablePage())
  if (table.page === 1) {
    fetchData()
  } else {
    dispatch(resetPage())
  }
}
