import _ from 'lodash'
import { store } from '../../../../App'
import { setFieldValue } from '../../../../redux/slices/inventory/dashboard'
import { defaultFilter } from '../model/defaultFilter'
import { fetchOverview } from './fetchOverview'

export const initialize = async () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const station = _.get(user, 'area[0].station')
  store.dispatch(
    setFieldValue({
      key: 'filter',
      value: {
        ...defaultFilter,
        station: station
          ? [
              {
                label: station.station,
                name: `${station.station.toLowerCase()}Checked`,
                uuid: station.uuid,
              },
            ]
          : [],
      },
    }),
  )
  store.dispatch(
    setFieldValue({
      key: 'overview',
      value: {},
    }),
  )
  await fetchOverview()
}
