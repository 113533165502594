import _ from 'lodash'
import { store } from '../../../../App'
import { getMonths } from './getMonths'
import { setReduxDashboardByKey } from 'src/redux/slices/budgetManagement'
import { themeColor } from 'src/utils/themeData'

export const initialize = async () => {
  let summary = [
    {
      title: 'ค่าใช้จ่ายทั้งหมดของ Academy',
      budget: 1000,
      actual: 2000,
      remaining: 0,
    },
    {
      title: 'YTD 01/2023 - 12/2023',
      budget: 0,
      actual: 0,
      remaining: 0,
    },
  ]
  summary = _.map(summary, (sum) => {
    const { actual, budget, remaining } = sum
    const budgetExceeded = actual >= budget && actual !== 0
    return {
      ...sum,
      budgetExceeded,
      remaining: actual - budget,
      colorActual: budgetExceeded
        ? themeColor.error.main
        : themeColor.success.content,
      colorRemaining:
        remaining < 0 ? themeColor.error.main : themeColor.text.secondary,
      bgColor: budgetExceeded ? themeColor.error.main : '#0B4F1F',
      percent: budgetExceeded
        ? 100
        : !_.isNaN(actual / budget)
        ? (actual / budget) * 100
        : 0,
    }
  })

  store.dispatch(
    setReduxDashboardByKey({
      key: 'summary',
      value: summary,
    }),
  )

  const months = getMonths()
  const monthlySummary = _.map(months, (month) => {
    const { monthEN, monthTH } = month
    const actual = 200000
    const budget = 1000000
    const remaining = budget - actual
    const budgetExceeded = actual >= budget && actual !== 0

    return {
      month: `${monthEN} ${monthTH}`,
      budget: budget,
      actual: actual,
      remaining: remaining,
      budgetExceeded,
      colorActual: budgetExceeded
        ? themeColor.error.main
        : themeColor.success.content,
      colorRemaining:
        remaining < 0 ? themeColor.error.main : themeColor.text.secondary,
      bgColor: budgetExceeded ? themeColor.error.main : '#0B4F1F',
      percent: budgetExceeded
        ? 100
        : !_.isNaN(actual / budget)
        ? (actual / budget) * 100
        : 0,
    }
  })

  store.dispatch(
    setReduxDashboardByKey({
      key: 'monthlySummary',
      value: monthlySummary,
    }),
  )
}
