import { Box, Typography } from '@mui/material'
import React from 'react'
import { Row, StyledBox } from '../Styled'
import FinancialColumn from './FinancialColumn'
import HorizontalBarChart from 'src/components/Chart/HorizontalBarChart'

const MonthlyFinancialDetailsBox = ({
  month,
  budget,
  actual,
  remaining,
  colorActual,
  colorRemaining,
  bgColor,
  percent,
  idx,
  budgetExceeded,
}) => {
  return (
    <StyledBox
      sx={{
        flexBasis: 'calc(33.33% - 11px)',
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="body2">{month}</Typography>
      <Box>
        <Typography variant="body1b" display="inline">
          Budget
        </Typography>{' '}
        <Typography variant="body2" display="inline">
          {budget.toLocaleString()} ฿
        </Typography>
      </Box>
      <HorizontalBarChart
        width="100%"
        height={32}
        labels={['']}
        dataSet={{
          backgroundColor: bgColor,
          data: percent,
        }}
        eleId={`monthlySummaryBudgetChart-${idx}`}
        borderRadius={10}
        barThickness={32}
      />
      <Row sx={{ width: '100%' }}>
        <FinancialColumn
          label="Actual"
          amount={actual}
          color={colorActual}
          budgetExceeded={budgetExceeded}
        />
        <FinancialColumn
          label="Remaining"
          amount={remaining}
          color={colorRemaining}
          budgetExceeded={budgetExceeded}
        />
      </Row>
    </StyledBox>
  )
}

export default MonthlyFinancialDetailsBox
