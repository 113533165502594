import { shallowEqual, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import LoadingPageCircular from '../../../components/CRUD/components/LoadingPageCircular'
import { initialize } from './handler/initialize'
import Header from './components/Header'
import FilterDrawer from './components/FilterDrawer'
import Content from './components/Content'
import TableDrawer from './components/TableDrawer'

const Index = () => {
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.inventoryDashboard.isLoading,
    }),
    shallowEqual,
  )
  useEffect(initialize, [])

  return (
    <Box px={2}>
      <Header />
      <Content />
      <FilterDrawer />
      <TableDrawer />
      <LoadingPageCircular isLoading={isLoading} />
    </Box>
  )
}

export default Index
